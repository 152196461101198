<div class="p-grid surveyGrid">
    <div class="p-col-md-fixed surveyLeftBorder1 bigScreenOnly"></div>
    <div class="p-col-md-fixed surveyLeftBorder2 bigScreenOnly"></div>
    <div class="p-col-fixed surveyLeftBorder3 bigScreenOnly">
        <div id="container">
            <h1 id='sectionTitleHeader'>Thank you for completing the IT/OT Assessment Survey</h1>
            <div class='headerLogo '>
                <div class='headerLogoContent'>
                    <img src="./assets/kearney_white.png" height="25">
                </div>
            </div>
        </div>
    </div>
    <div class="p-col surveyQuestions">
        <div id="container">
            <div class="content">
                <div class="p-grid">
                    <div class="p-col-12 thankYou">
                        Thank you
                    </div>
                    <div class="p-col-12 center">
                        <img src="./assets/kearney.png" width="500px">
                    </div>
                    <div class="p-col-12 thankYouText">
                        <b>Thank you text.</b><br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
            ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur 
            sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div>
                    <div class="p-col-12 thankYouText">
                        <b>Analysis / benchmark info?</b><br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
            ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur 
            sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div>
                    <div class="p-col-12 thankYouText">
                        For further information, please write to <a
                            href="#">contact e-mail</a>
                    </div>
                    <div class="p-col-12 center">
                        <button pButton class="analysisButton" type="button" label="Benchmark" (click) = "analysisButtonClicked()"></button>
                    </div>
                </div>
            </div>
            <app-survey-navigation [NextButtonVisible]='false'></app-survey-navigation>
        </div>
    </div>
</div>