import { Component, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { AnswerOptionResponse, AnswerResponse, LanguageEnum, QuestionAnswerInstanceResponse, ValidationTypeEnum, ValidatorResponse } from 'src/app/api/models';
import { MessageService, SelectItem } from 'primeng/api';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { Subject, Subscription } from 'rxjs';
import { SelectedOptionRequest } from 'src/app/api/models/selected-option-request';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { FreeTextRowAnswer } from '../free-text-row-answer';
import { QuestionBaseComponent } from '../question-base';
import { FOYDataService } from 'src/app/services/foy-data.service';
import { debounceTime } from 'rxjs/operators';
import { PendingChangesService } from 'src/app/surveys/pending-changes.service';
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent } from 'src/app/dialogs/information-dialog/information-dialog.component';

interface CellAnswer {
  answer: AnswerResponse,
  answerOption: AnswerOptionResponse
}

@Component({
  selector: 'app-matrix-numerical-question',
  templateUrl: './matrix-numerical-question.component.html',
  styleUrls: ['./matrix-numerical-question.component.scss']
})
export class MatrixNumericalQuestionComponent extends QuestionBaseComponent implements OnInit, OnDestroy, QuestionComponent {
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  providedTextListMatrix: string[][] = [];
  columnSum: number[] = [];
  rowSum: number[] = [];
  overallSum: number;

  fieldValidator: Validator[][] = [];
  fieldValidationMessages: string[][] = [];
  columnSumValidators: Validator[] = [];
  columnSumValidationMessages: string[] = [];

  excludeFromSum = []
  textAnswers: FreeTextRowAnswer[] = [];
  orderedColumnNames = [];
  orderedRowNames = [];
  rowTooltips = [];
  columnTooltips = [];

  showComponent = false;
  hints: string[][] = [];
  columnSumSuffix: string[] = [];
  rowSumSuffix: string[] = [];

  columnHeader: string;
  sumText: string;

  showColumnSum: boolean;
  showRowSum: boolean;
  showOverallSum: boolean;
  isPercentage: boolean;
  numberOfDigits: number;
  numberOfDigitsMatrix: number[][] = [];
  tooltip: string;

  DO_NOT_SHOW = 'DO_NOT_SHOW';

  private answerSavedSubscription: Subscription;

  private readonly debounceTimeMs = 3000; // Set the debounce time (in milliseconds)
  private saveQuestionnaireDataDebounced = new Subject();
  
  constructor(private translate: TranslateService,
    private foyDataService: FOYDataService,
    private pendingChangesService: PendingChangesService,
    private matDialog: MatDialog,
    languageService: LanguageService) {
    super(languageService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.saveQuestionnaireDataDebounced.pipe(debounceTime(this.debounceTimeMs)).subscribe(_ => {
      this.saveQuestionnaireData();
    });


    this.showColumnSum = this.questionData.matrixConfiguration?.showColumnSum ?? false;
    this.showRowSum = this.questionData.matrixConfiguration?.showRowSum ?? false;
    this.showOverallSum = this.showColumnSum && this.showRowSum;

    this.numberOfDigits = this.questionData.numberInputConfiguration?.numberOfDigits ?? 0;

    this.prepareData();
    
    this.columnHeader = this.questionData.matrixConfiguration?.columnHeader;
    this.sumText = this.questionData.matrixConfiguration?.sumText;

    this.isPercentage = this.questionData.numberInputConfiguration?.type == "%"

    this.textAnswers = new Array(this.orderedRowNames.length);

    this.questionData.matrixRowConfigurationCollection.filter(config => config.freeTextAnswer).forEach((row, index) => {
      const answerOptionId = row.freeTextAnswer.answerOptionCollection[0].id;
      const questionAnswerInstanceForFreeText = this.questionAnswerInstanceList.filter(a => a.answerOptionSelectedId === answerOptionId)[0];
      const freeText = questionAnswerInstanceForFreeText != null ? questionAnswerInstanceForFreeText.freeTextAnswer : '';
      this.textAnswers[row.matrixRowId - 1] = {
        freeText: freeText,
        answerOptionId: answerOptionId
      };
    });

    this.rowTooltips = new Array(this.orderedRowNames.length);
    this.questionData.matrixRowConfigurationCollection.filter(config => config.tooltipText).forEach((row, index) => {
      this.rowTooltips[row.matrixRowId - 1] = row.tooltipText;
    });
    
    this.tooltip = this.questionData.tooltipText;

    this.columnTooltips = new Array(this.orderedRowNames.length);
    this.questionData.matrixColumnConfigurationCollection.filter(config => config.tooltipText).forEach((column, index) => {
      this.columnTooltips[column.matrixColumnId - 1] = column.tooltipText;
    });

    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;
    if (!this.readonly) {
      this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
        data => {
          if (data.successStatus) {
            this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            if (this.showComponent === false) {
            }
          }
        });
    }

    this.foyDataService.$currencyHasChanged.subscribe(_ => this.setTexts());
  }

  getAnswerForCell(rowIndex, columnIndex) : CellAnswer {
    var answer = this.questionData.answerCollection.filter(a => a.matrixRowId === rowIndex + 1 && a.matrixColumnId === columnIndex + 1);
    if (answer == null || answer.length === 0) {
      return null;
    }
    return {
      answer: answer[0],
      answerOption: answer[0].answerOptionCollection[0]
    };
  }

  prepareData() {
    this.questionData.answerCollection = this.questionData
      .answerCollection
      .filter(a => a.matrixRowId != null && a.matrixColumnId != null) // filter out answers which are not in matrix (are row input text) 
      .sort((a, b) =>
      a.matrixRowId - b.matrixRowId || a.matrixColumnId - b.matrixColumnId);

    // this.orderedColumnNames = this.questionData.answerCollection.map(item => item.matrixColumnText)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    // this.orderedRowNames = this.questionData.answerCollection.map(item => item.matrixRowText)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    this.orderedColumnNames = this.questionData.matrixColumnConfigurationCollection.sort((a, b) => a.matrixColumnId - b.matrixColumnId).map(colConfig => colConfig.matrixColumnText)
    this.orderedRowNames = this.questionData.matrixRowConfigurationCollection.sort((a, b) => a.matrixRowId - b.matrixRowId).map(colConfig => colConfig.matrixRowText)

    this.excludeFromSum = new Array(this.orderedRowNames.length).fill(false)
    this.questionData.matrixRowConfigurationCollection.forEach((row, index) => {
      this.excludeFromSum[row.matrixRowId - 1] = row.shouldExcludeFromSum;
    });

    this.setTexts();

    this.calculateSumTable();
    this.setColumnSumValidators();
    this.setFieldValidators();
  }

  private setTexts() {
    this.orderedRowNames.forEach((rowName, iIndex) => {
      this.providedTextListMatrix.push(new Array(this.orderedRowNames.length));

      this.hints.push(new Array(this.orderedRowNames.length));
      this.numberOfDigitsMatrix.push(new Array(this.orderedRowNames.length));
      this.orderedColumnNames.forEach((colName, jIndex) => {
        const answers = this.getAnswerForCell(iIndex, jIndex);
        const answerOptionIdInCell = answers?.answerOption;
        const answer = answers?.answer;

        if (answerOptionIdInCell != null) {
          const questionAnswerInstanceForCell = this.questionAnswerInstanceList.filter(a => a.answerOptionSelectedId === answerOptionIdInCell.id)[0];
          const responseForAnswerOptionIdInCellText = questionAnswerInstanceForCell != null ? questionAnswerInstanceForCell.freeTextAnswer : null;
          this.providedTextListMatrix[iIndex][jIndex] = responseForAnswerOptionIdInCellText == '' ? null : responseForAnswerOptionIdInCellText;

          const cellValue = (questionAnswerInstanceForCell != null && questionAnswerInstanceForCell.freeTextAnswer != '' && questionAnswerInstanceForCell.freeTextAnswer != '')
            ? Number(questionAnswerInstanceForCell.freeTextAnswer)
            : null;
          this.columnSum[jIndex] += cellValue;

          this.numberOfDigitsMatrix[iIndex][jIndex] = this.foyDataService.getNumberOfDecimalPlaces(answer?.hint, this.numberOfDigits);

          this.hints[iIndex][jIndex] = answer?.hint != null ? ' ' + this.foyDataService.getTextWithCurrency(answer?.hint) : '';
        } else {
          this.providedTextListMatrix[iIndex][jIndex] = this.DO_NOT_SHOW;
        }
      });
    });

    this.columnSumSuffix = new Array(this.orderedColumnNames.length).fill(null);
    if (this.showColumnSum) {
      for (let jIndex = 0; jIndex < this.orderedColumnNames.length; jIndex++) {
        var hints = [];
        for (let iIndex = 1; iIndex < this.orderedRowNames.length; iIndex++) {
          if (!this.excludeFromSum[iIndex] && this.providedTextListMatrix[iIndex][jIndex] != this.DO_NOT_SHOW) {
            hints.push(this.hints[iIndex][jIndex]);
          }
        };

        if (this.areAllValuesSame(hints)) {
          this.columnSumSuffix[jIndex] = hints[0];
        }
      };
    }

    this.rowSumSuffix = new Array(this.orderedRowNames.length).fill(null);
    if (this.showRowSum) {
      for (let iIndex = 0; iIndex < this.orderedRowNames.length; iIndex++) {
        for (let jIndex = 0; jIndex < this.orderedColumnNames.length; jIndex++) {
          var hints = [];
          if (!this.excludeFromSum[iIndex] && this.providedTextListMatrix[iIndex][jIndex] != this.DO_NOT_SHOW) {
            hints.push(this.hints[iIndex][jIndex]);
          }
        };

        if (this.areAllValuesSame(hints)) {
          this.rowSumSuffix[iIndex] = hints[0];
        }
      };
    }
  }

  calculateSumTable() {
    this.columnSum = new Array(this.orderedColumnNames.length).fill(0);
    this.rowSum = new Array(this.orderedRowNames.length).fill(0);
    this.overallSum = 0;
    for (let iIndex = 0; iIndex < this.orderedRowNames.length; iIndex++) {
      for (let jIndex = 0; jIndex < this.orderedColumnNames.length; jIndex++) {
        const cellValue = this.getCellNumberValue(iIndex, jIndex);
        if (this.excludeFromSum[iIndex] == false) {
          this.columnSum[jIndex] += cellValue;
        }
        this.rowSum[iIndex] += cellValue;
        this.overallSum += cellValue;
      };
    };
  }

  private getCellNumberValue(rowIndex, columnIndex) {
    return this.providedTextListMatrix[rowIndex][columnIndex] != null && this.providedTextListMatrix[rowIndex][columnIndex] != this.DO_NOT_SHOW ? Number(this.providedTextListMatrix[rowIndex][columnIndex]) : 0;
  }

  answerSelected(event, i, j) {
    // if (event.originalEvent == null) {
    //   return;
    // }

    if (i != null && j != null) {
      var value = event.currentTarget.ariaValueNow;
      if (value === 'null') { // value which is not a number is converted to null string
        value = null;
      }
      if (this.providedTextListMatrix[i][j] == value) {
        return;
      }
      this.providedTextListMatrix[i][j] = value;
    }
    this.calculateSumTable();
    this.fillColumnSumValidationMessages();
    this.fillFieldValidationMessages();

    this.pendingChangesService.setHasPendingChanges(true);
    this.saveQuestionnaireDataDebounced.next();
  }

  private saveQuestionnaireData() {
    const selectedOptionRequestList = [];
    this.orderedRowNames.forEach((_, iIndex) => {
      this.orderedColumnNames.forEach((__, jIndex) => {
        var answers = this.getAnswerForCell(iIndex, jIndex);
        if (answers?.answerOption != null) {
          selectedOptionRequestList
            .push({ AnswerOptionId: answers.answerOption.id, FreeText: this.providedTextListMatrix[iIndex][jIndex] } as SelectedOptionRequest);
        }
      });
    });

    this.textAnswers.forEach((textAnswer, index) => {
      selectedOptionRequestList
        .push({ AnswerOptionId: textAnswer.answerOptionId, FreeText: textAnswer.freeText } as SelectedOptionRequest);
    });

    this.surveyStateService.saveQuestionAnswersCommand(selectedOptionRequestList, this.questionData.id);
  }

  ngOnDestroy(): void {
    if (this.answerSavedSubscription) {
      this.answerSavedSubscription.unsubscribe();
    }
  }

  // numberOnly(event): boolean {
  //   // const charCode = (event.which) ? event.which : event.keyCode;
  //   // if (charCode > 31 && charCode !== 44 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
  //   //   return false;
  //   // }
  //   return true;
  // }

  private fillColumnSumValidationMessages() {
    this.columnSumValidationMessages = new Array(this.orderedColumnNames.length).fill(null);

    this.columnSumValidators.forEach((v, index) => {
      if (v == null) {
        return;
      }
      var sum = this.columnSum[index];
      if (v.greaterThan != null && sum < v.greaterThan) {
        this.columnSumValidationMessages[index] = `${this.translate.instant('greaterThanError')} ${v.greaterThan}`;
      } else if (v.lessThan != null && sum > v.lessThan) {
        this.columnSumValidationMessages[index] = `${this.translate.instant('lessThanError')} ${v.lessThan}`;
      } else if (v.equals != null && sum !== v.equals) {
        this.columnSumValidationMessages[index] = `${this.translate.instant('equalsError')} ${v.equals}`;
      }
    });
  }

  private fillFieldValidationMessages() {
    this.fieldValidationMessages = Array.from({ length: this.orderedRowNames.length }, () => Array(this.orderedColumnNames.length).fill(null));

    this.fieldValidator.forEach((rows, rowIndex) => {

      rows.forEach((v, columnIndex) => {
        if (v == null) {
          return;
        }
        const cellValue = this.getCellNumberValue(rowIndex, columnIndex);

        if (v.greaterThan != null && cellValue < v.greaterThan) {
          this.fieldValidationMessages[rowIndex][columnIndex] = `${this.translate.instant('greaterThanError')} ${v.greaterThan}`;
        } else if (v.lessThan != null && cellValue > v.lessThan) {
          this.fieldValidationMessages[rowIndex][columnIndex] = `${this.translate.instant('lessThanError')} ${v.lessThan}`;
        } else if (v.equals != null && cellValue !== v.equals) {
          this.fieldValidationMessages[rowIndex][columnIndex] = `${this.translate.instant('equalsError')} ${v.equals}`;
        }
      })
    });
  }

  private isColumnSumValidator(validator: ValidatorResponse) {
    return validator.matrixColumnId != null && validator.matrixRowId == null
  }

  private isFieldValidator(validator: ValidatorResponse) {
    return validator.matrixColumnId != null && validator.matrixRowId != null
  }

  private isAllQuestionValidator(validator: ValidatorResponse) {
    return validator.matrixColumnId == null && validator.matrixRowId == null
  }

  private setColumnSumValidators() {
    this.columnSumValidators = new Array(this.orderedColumnNames.length).fill(null);
    this.columnSumValidationMessages = new Array(this.orderedColumnNames.length).fill(null);

    this.questionData.validatorsCollection.filter(this.isColumnSumValidator).forEach((v, index) => {

      var arrayIndex = v.matrixColumnId - 1;
      if (this.columnSumValidators[arrayIndex] == null) {
        this.columnSumValidators[arrayIndex] = {
          greaterThan: null,
          lessThan: null,
          equals: null
        };
      }

      switch (v.validationType) {
        case ValidationTypeEnum.MatrixColumnSumMax:
          this.columnSumValidators[arrayIndex].lessThan = v.value;
          break;
        case ValidationTypeEnum.MatrixColumnSumMin:
          this.columnSumValidators[arrayIndex].greaterThan = v.value;
          break;
        case ValidationTypeEnum.MatrixColumnSumEquals:
          this.columnSumValidators[arrayIndex].equals = v.value;
          break;
      }
    });

    this.fillColumnSumValidationMessages();
  }

  private setFieldValidators() {
    this.fieldValidator = Array.from({ length: this.orderedRowNames.length }, () => Array(this.orderedColumnNames.length).fill(null));
    this.fieldValidationMessages = Array.from({ length: this.orderedRowNames.length }, () => Array(this.orderedColumnNames.length).fill(null));

    this.questionData.validatorsCollection.filter(this.isFieldValidator).forEach((v, index) => {

      var columnIndex = v.matrixColumnId - 1;
      var rowIndex = v.matrixRowId - 1;

      if (this.fieldValidator[rowIndex][columnIndex] == null) {
        this.fieldValidator[rowIndex][columnIndex] = {
          greaterThan: null,
          lessThan: null,
          equals: null
        };
      }

      switch (v.validationType) {
        case ValidationTypeEnum.Max:
          this.fieldValidator[rowIndex][columnIndex].lessThan = v.value;
          break;
        case ValidationTypeEnum.Min:
          this.fieldValidator[rowIndex][columnIndex].greaterThan = v.value;
          break;
      }
    });

    this.questionData.validatorsCollection.filter(this.isAllQuestionValidator).forEach((v, index) => {

      for (let rowIndex = 0; rowIndex < this.orderedRowNames.length; rowIndex++) {
        for (let columnIndex = 0; columnIndex < this.orderedColumnNames.length; columnIndex++) {
          
          if (this.fieldValidator[rowIndex][columnIndex] == null) {
            this.fieldValidator[rowIndex][columnIndex] = {
              greaterThan: null,
              lessThan: null,
              equals: null
            };
          }
    
          switch (v.validationType) {
            case ValidationTypeEnum.Max:
              this.fieldValidator[rowIndex][columnIndex].lessThan = v.value;
              break;
            case ValidationTypeEnum.Min:
              this.fieldValidator[rowIndex][columnIndex].greaterThan = v.value;
              break;
          }

        }
      }

    });

    this.fillFieldValidationMessages();
  }

  private areAllValuesSame(array: any[]): boolean {
    if (array.length === 0) {
        return true;
    }

    return array.every(value => value === array[0]);
  }
}
