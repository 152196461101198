/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetFilledResponsesPercentageResponse } from '../models/get-filled-responses-percentage-response';
import { LanguageEnum } from '../models/language-enum';
import { QuestionAnswerGetResponse } from '../models/question-answer-get-response';
import { QuestionAnswerPostRequest } from '../models/question-answer-post-request';
import { QuestionAnswerPostResponse } from '../models/question-answer-post-response';

@Injectable({
  providedIn: 'root',
})
export class QuestionAnswerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiQuestionAnswerGet
   */
  static readonly ApiQuestionAnswerGetPath = '/api/QuestionAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAnswerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGet$Plain$Response(params?: {
    questionGroupId?: number;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionAnswerGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionAnswerService.ApiQuestionAnswerGetPath, 'get');
    if (params) {
      rb.query('questionGroupId', params.questionGroupId, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionAnswerGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAnswerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGet$Plain(params?: {
    questionGroupId?: number;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<QuestionAnswerGetResponse> {

    return this.apiQuestionAnswerGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionAnswerGetResponse>) => r.body as QuestionAnswerGetResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAnswerGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGet$Json$Response(params?: {
    questionGroupId?: number;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionAnswerGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionAnswerService.ApiQuestionAnswerGetPath, 'get');
    if (params) {
      rb.query('questionGroupId', params.questionGroupId, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionAnswerGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAnswerGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGet$Json(params?: {
    questionGroupId?: number;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<QuestionAnswerGetResponse> {

    return this.apiQuestionAnswerGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionAnswerGetResponse>) => r.body as QuestionAnswerGetResponse)
    );
  }

  /**
   * Path part for operation apiQuestionAnswerPost
   */
  static readonly ApiQuestionAnswerPostPath = '/api/QuestionAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAnswerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionAnswerPost$Plain$Response(params?: {
    context?: HttpContext
    body?: QuestionAnswerPostRequest
  }
): Observable<StrictHttpResponse<QuestionAnswerPostResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionAnswerService.ApiQuestionAnswerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionAnswerPostResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAnswerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionAnswerPost$Plain(params?: {
    context?: HttpContext
    body?: QuestionAnswerPostRequest
  }
): Observable<QuestionAnswerPostResponse> {

    return this.apiQuestionAnswerPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionAnswerPostResponse>) => r.body as QuestionAnswerPostResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAnswerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionAnswerPost$Json$Response(params?: {
    context?: HttpContext
    body?: QuestionAnswerPostRequest
  }
): Observable<StrictHttpResponse<QuestionAnswerPostResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionAnswerService.ApiQuestionAnswerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionAnswerPostResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAnswerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionAnswerPost$Json(params?: {
    context?: HttpContext
    body?: QuestionAnswerPostRequest
  }
): Observable<QuestionAnswerPostResponse> {

    return this.apiQuestionAnswerPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionAnswerPostResponse>) => r.body as QuestionAnswerPostResponse)
    );
  }

  /**
   * Path part for operation apiQuestionAnswerGetAnswersForSurveyGet
   */
  static readonly ApiQuestionAnswerGetAnswersForSurveyGetPath = '/api/QuestionAnswer/get-answers-for-survey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAnswerGetAnswersForSurveyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGetAnswersForSurveyGet$Plain$Response(params?: {
    questionGroupId?: number;
    surveyInstanceId?: number;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionAnswerGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionAnswerService.ApiQuestionAnswerGetAnswersForSurveyGetPath, 'get');
    if (params) {
      rb.query('questionGroupId', params.questionGroupId, {});
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionAnswerGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAnswerGetAnswersForSurveyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGetAnswersForSurveyGet$Plain(params?: {
    questionGroupId?: number;
    surveyInstanceId?: number;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<QuestionAnswerGetResponse> {

    return this.apiQuestionAnswerGetAnswersForSurveyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionAnswerGetResponse>) => r.body as QuestionAnswerGetResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAnswerGetAnswersForSurveyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGetAnswersForSurveyGet$Json$Response(params?: {
    questionGroupId?: number;
    surveyInstanceId?: number;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionAnswerGetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionAnswerService.ApiQuestionAnswerGetAnswersForSurveyGetPath, 'get');
    if (params) {
      rb.query('questionGroupId', params.questionGroupId, {});
      rb.query('surveyInstanceId', params.surveyInstanceId, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionAnswerGetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAnswerGetAnswersForSurveyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGetAnswersForSurveyGet$Json(params?: {
    questionGroupId?: number;
    surveyInstanceId?: number;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<QuestionAnswerGetResponse> {

    return this.apiQuestionAnswerGetAnswersForSurveyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionAnswerGetResponse>) => r.body as QuestionAnswerGetResponse)
    );
  }

  /**
   * Path part for operation apiQuestionAnswerGetFilledResponsesPercentageGet
   */
  static readonly ApiQuestionAnswerGetFilledResponsesPercentageGetPath = '/api/QuestionAnswer/get-filled-responses-percentage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAnswerGetFilledResponsesPercentageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGetFilledResponsesPercentageGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetFilledResponsesPercentageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionAnswerService.ApiQuestionAnswerGetFilledResponsesPercentageGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetFilledResponsesPercentageResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAnswerGetFilledResponsesPercentageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGetFilledResponsesPercentageGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<GetFilledResponsesPercentageResponse> {

    return this.apiQuestionAnswerGetFilledResponsesPercentageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetFilledResponsesPercentageResponse>) => r.body as GetFilledResponsesPercentageResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionAnswerGetFilledResponsesPercentageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGetFilledResponsesPercentageGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetFilledResponsesPercentageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuestionAnswerService.ApiQuestionAnswerGetFilledResponsesPercentageGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetFilledResponsesPercentageResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiQuestionAnswerGetFilledResponsesPercentageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiQuestionAnswerGetFilledResponsesPercentageGet$Json(params?: {
    context?: HttpContext
  }
): Observable<GetFilledResponsesPercentageResponse> {

    return this.apiQuestionAnswerGetFilledResponsesPercentageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetFilledResponsesPercentageResponse>) => r.body as GetFilledResponsesPercentageResponse)
    );
  }

}
