import { Injectable } from "@angular/core";
import { FOYData } from "./foy-data";
import { FoyService } from "../api/services";
import { LanguageService } from "./language.service";
import { Subject } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FOYDataService {

  private currencyQuestionId = 117101;
  private currencyTag = "$currency$";
  private currencySignsMapping: { [id: string]: string; } = {
    "EUR": "€",
    "USD": "$"
  };

  public data: FOYData;
  public $currencyHasChanged = new Subject<string>();

  constructor(private foyService: FoyService,
    private languageService: LanguageService) {
    this.init();
  }

  init() {
    this.data = {
      currentCurrency: null
    }
    this.updateCurrency();
  }

  updateCurrency() {
    this.foyService
      .apiFoyGet$Plain({
        language: this.languageService.language
      })
      .subscribe(currency => {
        this.data.currentCurrency = currency;
        this.$currencyHasChanged.next(currency);
      });
  }

  updateCurrencyForUser(userId: number) {
    return this.foyService
      .apiFoyGetForUserGet$Plain({
        language: this.languageService.language,
        userId: userId
      })
      .pipe(
        tap(currency => {
          this.data.currentCurrency = currency;
          this.$currencyHasChanged.next(currency);
      }));
  }

  isCurrencyQuestion(questionId: number) {
    return questionId == this.currencyQuestionId;
  }

  getNumberOfDecimalPlaces(hint: string, defaultNumberOfDigits: number) {
    if (hint == null) {
      return defaultNumberOfDigits;
    }
    if (this.isCurrencyThousandText(hint)) {
      return 0;
    } 
    if (this.isFTEText(hint)) {
      return 1;
    }
    if (this.isHoursText(hint)) {
      return 1;
    }

    return defaultNumberOfDigits;
  }

  isCurrencyThousandText(text: string) {
    return text.includes(`k ${this.currencyTag}`);
  }

  isFTEText(text: string) {
    return text.includes('FTE');
  }

  isHoursText(text: string) {
    return text.includes('hrs')
        || text.includes('Std');
  }

  getTextWithCurrency(text: string) {
    const currentCurrency = this.data.currentCurrency;

    var currencyText = this.currencySignsMapping[currentCurrency]
      ? this.currencySignsMapping[currentCurrency]
      : currentCurrency;

    if (currencyText == null) {
      return '';
    }

    return text.replace(this.currencyTag, currencyText);
  }
}