<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="grid answersGrid">
    <div class="col-12">
      <div class="grid answersGrid tableHeader">
        <div class="col md">
        </div>
        <div class="md tableHeader" *ngFor="let column of orderedColumnNames; index as i"
          [ngClass]="{ 'col-6': columnTypes[i] == columnTypeEnum.Text, 'col': columnTypes[i] != columnTypeEnum.Text }">
          <div fxLayout="row">
            <span [innerHTML]="column"></span>
            <div *ngIf="columnTooltips[i]">
              <mat-icon [pTooltip]="columnTooltips[i]">info</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngFor="let row of orderedRowNames; index as i">
        <div class="grid answersGrid">
          <div class="col md" [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <div fxLayout="row">
              <span [innerHTML]="row"></span>
              <div *ngIf="rowTooltips[i]">
                <mat-icon [pTooltip]="rowTooltips[i]">info</mat-icon>
              </div>
            </div>
            <div class="p-col-12" *ngIf="textAnswers[i] != null">
              <input pInputText type="text" class='textAnswer text-input' [style]="{'width': '100%'}" pInputText [(ngModel)]="textAnswers[i].freeText" (focusout)="answerSelected($event, false)" [disabled]="readonly"/>
            </div>
          </div>
          <div class="md answerCell" *ngFor="let column of orderedColumnNames; index as j"
            [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0, 'col-6': columnTypes[j] == columnTypeEnum.Text, 'col': columnTypes[j] != columnTypeEnum.Text }">
            <span style="width: 100%">
              <p-dropdown *ngIf="columnTypes[j] == columnTypeEnum.Dropdown"
                [options]="selectedOptionsForCell[i][j]"
                [(ngModel)]="selectedAnswerOptionIdList[i][j]"
                optionLabel="label"
                [disabled]="readonly"
                (onChange)="answerSelected($event, true)"
                [autoWidth]="false"
                [style]="{'minWidth':'100%'}"></p-dropdown>
            </span>
            <input type="text" pInputText *ngIf="columnTypes[j] == columnTypeEnum.Text" [(ngModel)]="providedTextListMatrix[i][j].text" [disabled]="readonly"
              (focusout)="answerSelected($event, false)"/>
          </div>
        </div>
      </div>
      <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
    </div>
  </div>
</div>