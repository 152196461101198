import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressSpinnerService {

  progressServiceSubject: Subject<boolean> = new Subject();
  public state$: Observable<boolean> = this.progressServiceSubject.asObservable();

  constructor() { }

  show() {
    this.progressServiceSubject.next(true);
  }
  hide() {
    this.progressServiceSubject.next(false);
  }
}
