import { ElementRef, Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { forkJoin, from, Observable, of, timer } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';

interface Data {
  element: HTMLElement,
  imgData: string,
  marginX: number,
  marginY: number,
  imgWidth: number,
  imgHeight: number
}

@Injectable({
  providedIn: 'root'
})
export class PdfGenerationService {
  public generatePdf(
      elements: HTMLElement[],
      fileName: string
    ): Observable<unknown> {

    const pdf = new jsPDF('landscape');

    const data = elements.map(element => <Data>{ element: element });
    var $tasks = []
    data.forEach(d => $tasks.push(this.fillDataWithImage(d)));

    return forkJoin($tasks).pipe(tap(_ => {
      for (let i = 0; i < data.length; i++) {
        let d = data[i];
        if (i > 0) {
          pdf.addPage();
        }
        // pdf.html()
        pdf.addImage(d.imgData, 'JPEG', d.marginX, d.marginY, d.imgWidth, d.imgHeight, undefined, 'FAST');
      }
      pdf.save(`${fileName}.pdf`);
    }))
  }

  fillDataWithImage(data: Data) {
    return from(html2canvas(data.element, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');

      const pageWidth = 297;
      const pageHeight = 210;
  
      const maxImagewidth = 0.95 * pageWidth;
      const maxImageHeight = 0.95 * pageHeight;
  
      let ratio = Math.max(canvas.width / maxImagewidth, canvas.height / maxImageHeight);
  
      if (ratio < 1) {
        ratio = 1;
      }
  
      const imgWidth = canvas.width / ratio;
      const imgHeight = canvas.height / ratio;
      const marginX = (pageWidth - imgWidth) / 2;
      const marginY = (pageHeight - maxImageHeight) / 2;

      data.imgData = imgData;
      data.marginX = marginX;
      data.marginY = marginY;
      data.imgWidth = imgWidth;
      data.imgHeight = imgHeight;
    }))
  }
}
