import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionAnswerInstanceResponse } from 'src/app/api/models';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { SelectedOptionRequest } from 'src/app/api/models/selected-option-request';

@Component({
  selector: 'app-multi-level',
  templateUrl: './multi-level.component.html',
  styleUrls: ['./multi-level.component.scss']
})
export class MultiLevelComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  selectedAnswerOptionId: number;
  selectedAnswerOptionFreeText: string;
  showComponent = false;
  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
    this.selectedAnswerOptionId = this.questionAnswerInstanceList.map(a => a.answerOptionSelectedId)[0];
    this.selectedAnswerOptionFreeText = this.questionAnswerInstanceList.map(a => a.freeTextAnswer)[0];
    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (data.successStatus) {
          this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
          if (this.showComponent === false) {
            this.selectedAnswerOptionId = null;
            this.selectedAnswerOptionFreeText = '';
          }
        }
      });
  }

  answerSelected() {
    const freeTextAnswerSlected = this.questionData.answerCollection.filter(a => a.answerOptionCollection[0].id === this.selectedAnswerOptionId)[0].answerOptionCollection[0].isFreeText;
    if (!freeTextAnswerSlected) {
      this.selectedAnswerOptionFreeText = '';
    }
    let selectedOption = [({ answerOptionId: this.selectedAnswerOptionId, freeText: this.selectedAnswerOptionFreeText } as SelectedOptionRequest)];
    if (freeTextAnswerSlected && (this.selectedAnswerOptionFreeText && this.selectedAnswerOptionFreeText.length === 0 || /[a-zA-Z]/.test(this.selectedAnswerOptionFreeText) === false)) {
      selectedOption = [];
    }
    this.surveyStateService.saveQuestionAnswersCommand(
      selectedOption,
      this.questionData.id);
  }

  labelClicked(answerOptionId) {
    this.selectedAnswerOptionId = answerOptionId;
    this.answerSelected();
  }

  ngOnDestroy(): void {
    this.answerSavedSubscription.unsubscribe();
  }

}
