import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LanguageEnum } from '../api/models';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public language: LanguageEnum = LanguageEnum.English;

  public languageChanged$ = new Subject<LanguageEnum>();

  changeLanguage(language: LanguageEnum) {
    this.language = language;
    this.languageChanged$.next(language);
  }

  getLanguageEnum(languageCode: string): LanguageEnum | null {
    switch (languageCode) {
      case 'en':
        return LanguageEnum.English;
      case 'de':
        return LanguageEnum.German;
      default:
        return null;
    }
  }
}
