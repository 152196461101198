/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UploadedFileResponse } from '../models/uploaded-file-response';

@Injectable({
  providedIn: 'root',
})
export class FilesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiFilesGet
   */
  static readonly ApiFilesGetPath = '/api/Files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFilesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilesGet$Plain$Response(params?: {
    questionAnswerInstanceId?: number;
    questionId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UploadedFileResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, FilesService.ApiFilesGetPath, 'get');
    if (params) {
      rb.query('questionAnswerInstanceId', params.questionAnswerInstanceId, {});
      rb.query('questionId', params.questionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UploadedFileResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFilesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilesGet$Plain(params?: {
    questionAnswerInstanceId?: number;
    questionId?: number;
    context?: HttpContext
  }
): Observable<Array<UploadedFileResponse>> {

    return this.apiFilesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UploadedFileResponse>>) => r.body as Array<UploadedFileResponse>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFilesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilesGet$Json$Response(params?: {
    questionAnswerInstanceId?: number;
    questionId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UploadedFileResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, FilesService.ApiFilesGetPath, 'get');
    if (params) {
      rb.query('questionAnswerInstanceId', params.questionAnswerInstanceId, {});
      rb.query('questionId', params.questionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UploadedFileResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFilesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilesGet$Json(params?: {
    questionAnswerInstanceId?: number;
    questionId?: number;
    context?: HttpContext
  }
): Observable<Array<UploadedFileResponse>> {

    return this.apiFilesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UploadedFileResponse>>) => r.body as Array<UploadedFileResponse>)
    );
  }

  /**
   * Path part for operation apiFilesPost
   */
  static readonly ApiFilesPostPath = '/api/Files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFilesPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiFilesPost$Plain$Response(params?: {
    QuestionId?: number;
    AnswerOptionId?: number;
    FreeText?: string;
    context?: HttpContext
    body?: {
'Files'?: Array<Blob>;
}
  }
): Observable<StrictHttpResponse<Array<UploadedFileResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, FilesService.ApiFilesPostPath, 'post');
    if (params) {
      rb.query('QuestionId', params.QuestionId, {});
      rb.query('AnswerOptionId', params.AnswerOptionId, {});
      rb.query('FreeText', params.FreeText, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UploadedFileResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFilesPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiFilesPost$Plain(params?: {
    QuestionId?: number;
    AnswerOptionId?: number;
    FreeText?: string;
    context?: HttpContext
    body?: {
'Files'?: Array<Blob>;
}
  }
): Observable<Array<UploadedFileResponse>> {

    return this.apiFilesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UploadedFileResponse>>) => r.body as Array<UploadedFileResponse>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFilesPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiFilesPost$Json$Response(params?: {
    QuestionId?: number;
    AnswerOptionId?: number;
    FreeText?: string;
    context?: HttpContext
    body?: {
'Files'?: Array<Blob>;
}
  }
): Observable<StrictHttpResponse<Array<UploadedFileResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, FilesService.ApiFilesPostPath, 'post');
    if (params) {
      rb.query('QuestionId', params.QuestionId, {});
      rb.query('AnswerOptionId', params.AnswerOptionId, {});
      rb.query('FreeText', params.FreeText, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UploadedFileResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFilesPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiFilesPost$Json(params?: {
    QuestionId?: number;
    AnswerOptionId?: number;
    FreeText?: string;
    context?: HttpContext
    body?: {
'Files'?: Array<Blob>;
}
  }
): Observable<Array<UploadedFileResponse>> {

    return this.apiFilesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UploadedFileResponse>>) => r.body as Array<UploadedFileResponse>)
    );
  }

  /**
   * Path part for operation apiFilesDelete
   */
  static readonly ApiFilesDeletePath = '/api/Files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFilesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilesDelete$Response(params?: {
    fileId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FilesService.ApiFilesDeletePath, 'delete');
    if (params) {
      rb.query('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFilesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilesDelete(params?: {
    fileId?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiFilesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiFilesDownloadFilesGet
   */
  static readonly ApiFilesDownloadFilesGetPath = '/api/Files/download-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFilesDownloadFilesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilesDownloadFilesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FilesService.ApiFilesDownloadFilesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFilesDownloadFilesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilesDownloadFilesGet(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiFilesDownloadFilesGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
