<div class='navigationPlaceholder'>
</div>
<div class='navigationWrapper' [style.margin-bottom.px]="marginBottom ?? 0">
  <div class='navigation'>
    <button pButton id="prevButton" type="button" icon="pi pi-step-backward" (click)="buttonPreviousClicked()"
      *ngIf="PrevButtonVisible"></button>
    <p-progressBar id="progress" [value]="progess"></p-progressBar>
    <button pButton id="nextButton" type="button" icon="pi pi-step-forward" (click)="buttonNextClicked()"
      *ngIf="NextButtonVisible">
    </button>
  </div>
</div>