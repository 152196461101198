import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { MessageService } from 'primeng/api';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { Subscription } from 'rxjs';
import { QuestionAnswerInstanceResponse } from 'src/app/api/models';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { SelectedOptionRequest } from 'src/app/api/models/selected-option-request';
import { KeyedCollection } from 'src/app/IKeyedCollection';

@Component({
  selector: 'app-multi-select-question',
  templateUrl: './multi-select-question.component.html',
  styleUrls: ['./multi-select-question.component.scss']
})
export class MultiSelectQuestionComponent implements OnInit, OnDestroy, QuestionComponent {
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  selectedAnswerOptionIdList = [];
  selectedAnswerOptionTextCollection: KeyedCollection<any> = new KeyedCollection<any>();
  showComponent = false;
  private answerSavedSubscription: Subscription;
  constructor() {
  }

  ngOnInit() {
    this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
    this.questionData.answerCollection
      .forEach(qai => this.selectedAnswerOptionTextCollection.Add(qai.answerOptionCollection[0].id.toString(), { value: null }));
    this.questionAnswerInstanceList.forEach(qai => {
      this.selectedAnswerOptionIdList.push(qai.answerOptionSelectedId);
      this.selectedAnswerOptionTextCollection.Item(qai.answerOptionSelectedId.toString()).value = qai.freeTextAnswer;
    });

    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (data.successStatus) {
          this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
          if (this.showComponent === false) {
            this.selectedAnswerOptionIdList = [];
          }
        }
      });
  }

  toggleSelected(selectedAnswerOptionId) {
    if (this.questionData.questionType.name === 'MultiSelectMax3Answers' && this.selectedAnswerOptionIdList.length > 3) {
      this.showError('Max 3 answers allowed.');
      this.selectedAnswerOptionIdList = this.selectedAnswerOptionIdList.filter(a => a !== selectedAnswerOptionId);
    } else if (!this.validate(this.selectedAnswerOptionIdList)) {
      this.surveyStateService.deleteQuestionAnswersCommand(this.questionData.id);
    } else {
      const selectedOption = this.selectedAnswerOptionIdList
        .map(a => ({
          AnswerOptionId: a, FreeText: this.selectedAnswerOptionTextCollection.Item(a)
            ? this.selectedAnswerOptionTextCollection.Item(a).value : null
        } as SelectedOptionRequest));
      this.surveyStateService.saveQuestionAnswersCommand(
        selectedOption,
        this.questionData.id);
    }
  }

  validate(selectedAnswerOptionIdList) {
    let isValid = true;
    selectedAnswerOptionIdList.forEach(selectedAnswerOptionId => {
      if (this.isSelectedOptionTextFieldEmpty(selectedAnswerOptionId)) {
        isValid = false;
      }
    });
    return isValid;
  }

  isSelectedOptionTextFieldEmpty(selectedAnswerOptionId) {
    const selectedAswerOption = this.questionData.answerCollection.filter(a => a.answerOptionCollection[0].id === selectedAnswerOptionId)[0].answerOptionCollection[0];
    const selectedTextItem = this.selectedAnswerOptionTextCollection.Item(selectedAnswerOptionId);
    let selectedText = null;
    if (selectedTextItem) {
      selectedText = selectedTextItem.value;
    }
    return selectedAswerOption.isFreeText && (selectedText == null || selectedText.length === 0 || /[a-zA-Z]/.test(selectedText) === false);
  }


  labelClicked(answerOptionCollectionId) {
    this.selectedAnswerOptionIdList = JSON.parse(JSON.stringify(this.selectedAnswerOptionIdList));
    const index = this.selectedAnswerOptionIdList.indexOf(answerOptionCollectionId);
    if (this.selectedAnswerOptionIdList.indexOf(answerOptionCollectionId) > -1) {
      this.selectedAnswerOptionIdList.splice(index, 1);
    } else {
      this.selectedAnswerOptionIdList.push(answerOptionCollectionId);
    }
    this.toggleSelected(answerOptionCollectionId);
  }

  showError(message) {
    this.messageService.clear();
    this.messageService.add({ severity: 'error', summary: 'Too many answers.', detail: message, sticky: false, life: 4000 });
  }

  ngOnDestroy(): void {
    this.answerSavedSubscription.unsubscribe();
  }
}
