/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthResponse } from '../models/auth-response';
import { LanguageEnum } from '../models/language-enum';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUserResetPut
   */
  static readonly ApiUserResetPutPath = '/api/User/reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserResetPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserResetPut$Response(params?: {
    email?: string;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserResetPutPath, 'put');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserResetPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserResetPut(params?: {
    email?: string;
    language?: LanguageEnum;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiUserResetPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUserSetNewPasswordPut
   */
  static readonly ApiUserSetNewPasswordPutPath = '/api/User/set-new-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserSetNewPasswordPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserSetNewPasswordPut$Response(params?: {
    guid?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserSetNewPasswordPutPath, 'put');
    if (params) {
      rb.query('guid', params.guid, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserSetNewPasswordPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserSetNewPasswordPut(params?: {
    guid?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiUserSetNewPasswordPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUserPasswordauthenticateGet
   */
  static readonly ApiUserPasswordauthenticateGetPath = '/api/User/passwordauthenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPasswordauthenticateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserPasswordauthenticateGet$Plain$Response(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPasswordauthenticateGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPasswordauthenticateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserPasswordauthenticateGet$Plain(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<AuthResponse> {

    return this.apiUserPasswordauthenticateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AuthResponse>) => r.body as AuthResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPasswordauthenticateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserPasswordauthenticateGet$Json$Response(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPasswordauthenticateGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPasswordauthenticateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserPasswordauthenticateGet$Json(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<AuthResponse> {

    return this.apiUserPasswordauthenticateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AuthResponse>) => r.body as AuthResponse)
    );
  }

  /**
   * Path part for operation apiUserTestGet
   */
  static readonly ApiUserTestGetPath = '/api/User/test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserTestGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserTestGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserTestGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserTestGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserTestGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.apiUserTestGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserTestGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserTestGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserTestGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserTestGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserTestGet$Json(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.apiUserTestGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
