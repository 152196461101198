import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { QuestionAnswerInstanceResponse, AnswerOptionResponse } from 'src/app/api/models';
import { MessageService } from 'primeng/api';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { Subscription } from 'rxjs';
import { SelectedOptionRequest } from 'src/app/api/models/selected-option-request';

@Component({
  selector: 'app-single-select-series-question',
  templateUrl: './single-select-series-question.component.html',
  styleUrls: ['./single-select-series-question.component.scss']
})
export class SingleSelectSeriesQuestionComponent implements OnInit, OnDestroy, QuestionComponent {
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  selectedAnswerOptions = [];
  orderedColumnNames = [];
  orderedRowNames = [];
  showComponent = false;


  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.prepareData();
    this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (data.successStatus) {
          this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
          if (this.showComponent === false) {
            this.selectedAnswerOptions = [];
          }
        }
      });
  }

  prepareData() {
    this.questionData.answerCollection = this.questionData.answerCollection.sort((a, b) =>
      a.matrixRowId - b.matrixRowId || a.matrixColumnId - b.matrixColumnId);

    this.orderedColumnNames = this.questionData.matrixColumnConfigurationCollection.sort(colConfig => colConfig.matrixColumnId).map(colConfig => colConfig.matrixColumnText)
    this.orderedRowNames = this.questionData.matrixRowConfigurationCollection.sort(rowConfig => rowConfig.matrixRowId).map(colConfig => colConfig.matrixRowText)
    // this.orderedColumnNames = this.questionData.matrix..answerCollection.map(item => item.matrixColumnText)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    // this.orderedRowNames = this.questionData.answerCollection.map(item => item.matrixRowText)
    //   .filter((value, index, self) => self.indexOf(value) === index);

    this.orderedRowNames.forEach((rowName, index) => {
      const answerOptionIdsInRow = this.questionData.answerCollection.filter(a => a.matrixRowId === index + 1).map(a => a.answerOptionCollection[0]).map(a => a.id);
      this.selectedAnswerOptions[index] =
        this.questionAnswerInstanceList.filter(a => answerOptionIdsInRow.some(b => b === a.answerOptionSelectedId)).map(a => a.answerOptionSelectedId)[0];
    });
  }

  getOptionsForCell(matrixRowId, matrixColumnId): AnswerOptionResponse {
    return this.questionData.answerCollection.filter(a => a.matrixRowId === matrixRowId && a.matrixColumnId === matrixColumnId)[0].answerOptionCollection[0];
  }

  ngOnDestroy(): void {
    this.answerSavedSubscription.unsubscribe();
  }
  answerSelected(event) {
    if (this.areAllAnswerSelected()) {
      this.surveyStateService.saveQuestionAnswersCommand(
        this.selectedAnswerOptions.map(a => ({ AnswerOptionId: a, FreeText: null } as SelectedOptionRequest)),
        this.questionData.id);
    }
  }

  areAllAnswerSelected() {
    return this.selectedAnswerOptions.findIndex(a => a === null || a === undefined) === -1;
  }
}
