<div class="p-grid surveyGrid">
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div>
          <form [formGroup]="form" (ngSubmit)="buttonClicked()">
            <div class="registerButtonWrapper">
            
              <div class="input-container">
                <label class="regLabel" for="pin"> {{ 'Password' | translate }}*</label>
                <p-password id="pin" type="text" styleClass="input-width" inputStyleClass="input-width" [toggleMask]="true" [feedback]="false" formControlName="password"></p-password>
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="confirmPassword"> {{ 'Confirm Password' | translate }}*</label>
                <p-password id="pin" type="text" styleClass="input-width" inputStyleClass="input-width" [toggleMask]="true" [feedback]="false" formControlName="confirmPassword"></p-password>
              </div>
            
              <br />
              <button pButton class="button" type="button" label="Set new password" (click)="buttonClicked()"
                [disabled]='!isValid()'></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>