<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="p-grid answersGrid">
    <div class="p-col-12">
      <div class="p-grid answersGrid tableHeader">
        <div class="p-col p-md">
        </div>
        <div class="p-col p-md tableHeader" *ngFor="let column of orderedColumnNames">
          <span [innerHTML]="column"></span>
        </div>
      </div>
      <div class="p-col-12" *ngFor="let row of orderedRowNames; index as i">
        <div class="p-grid answersGrid">
          <div class="p-col p-md" [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <span [innerHTML]="row"></span>
          </div>
          <div class="p-col p-md answerCell" *ngFor="let column of orderedColumnNames; index as j"
            [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <p-autoComplete [autocomplete]="off" [(ngModel)]="selectedAnswerOptionList[i][j]"
              [suggestions]="selectedOptionsForCellSuggestions[i][j]" (completeMethod)="search($event,i,j)"
              (onSelect)="answerSelected($event)" field="label" [dropdown]="true"></p-autoComplete>
          </div>
        </div>
      </div>
      <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
    </div>
  </div>
</div>