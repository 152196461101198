<div class="p-grid surveyGrid">
  <!-- <div class="p-col-md-fixed surveyLeftBorder1 bigScreenOnly"></div>
  <div class="p-col-md-fixed surveyLeftBorder2 bigScreenOnly"></div>
  <div class="p-col-fixed surveyLeftBorder3 bigScreenOnly">
    <div id="container">
      <h1 id='sectionTitleHeader'>The data you provide for the assessment will be collected, stored and handled securely
      </h1>
      <div class='headerLogo'>
        <div class='headerLogoContent bigScreenOnly'>
          <img src="./assets/kearney_white.png" height="25">
        </div>
      </div>
    </div>
  </div> -->
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div class="p-grid">
          <div class="p-col-12">
            <h1>{{ 'Data protection' | translate }}</h1>
            
            <p class="welcomeText1" [innerHTML]="'Data protection text 1' | translate">

            <p class="welcomeText1" [innerHTML]="'Data protection text 2' | translate"></p>

            <p class="welcomeText1" [innerHTML]="'Data protection text 3' | translate"></p>

            <h3>{{ 'Download PDF document:' | translate }}</h3>

            <p>
              <a [routerLink]="['/privacy-policy']">{{ ' Privacy Policy and Duty to Provide Information' | translate }}</a>
            </p>


            <h3>{{ 'Do you have any questions concerning data protection?' | translate }}</h3>
            <p class="welcomeText1" [innerHTML]="'Questions text' | translate"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="back-container">
      <button pButton class="button submit-button" type="button" icon="pi pi-home" (click)="homeClicked()">
        &nbsp;{{ 'Home' | translate }}
      </button>
    </div>
  </div>
</div>
