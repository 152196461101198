<div class="p-grid surveyGrid">
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div>
          <form [formGroup]="registrationForm" (ngSubmit)="buttonRegisterClicked()">
            <div class="registerButtonWrapper">
              <div class="input-container">
                <label class="regLabel" for="company"> {{ 'Company name' | translate }}*</label>
                <input pInputText type="text" class="input-width" id="company" formControlName="company">
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="selfDescription"> {{ 'How do you describe yourself' | translate }}*</label>
                <p-dropdown styleClass="input-width" inputStyleClass="input-width" class="dropDown orderedCategoryDropdownThemeClass input-width" formControlName="selfDescription" [options]="genderOptions">
                  <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="registrationForm.value.selfDescription">
                        <div>{{ registrationForm.value.selfDescription | translate }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                      <div class="flex align-items-center gap-2">
                          <div>{{ item | translate }}</div>
                      </div>
                  </ng-template>
                </p-dropdown>
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="firstName"> {{ 'First name' | translate }}*</label>
                <input pInputText type="text" id="firstName" class="input-width" formControlName="firstName">
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="lastName"> {{ 'Last name' | translate }}*</label>
                <input pInputText type="text" id="lastName" class="input-width" formControlName="lastName">
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="phone"> {{ 'Phone' | translate }}*</label>
                <input pInputText type="tel" id="phone" class="input-width" formControlName="phone">
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="fax"> {{ 'Fax' | translate }}</label>
                <input pInputText type="tel" id="fax" class="input-width" formControlName="fax">
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="email"> {{ 'E-Mail' | translate }}*</label>
                <input pInputText type="email" id="email" class="input-width" formControlName="email">
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="pin"> {{ 'Password' | translate }}*</label>
                <p-password id="pin" type="text" styleClass="input-width" inputStyleClass="input-width" [toggleMask]="true" [feedback]="false" formControlName="password"></p-password>
              </div>
            
              <div class="input-container">
                <label class="regLabel" for="confirmPassword"> {{ 'Confirm Password' | translate }}*</label>
                <p-password id="pin" type="text" styleClass="input-width" inputStyleClass="input-width" [toggleMask]="true" [feedback]="false" formControlName="confirmPassword"></p-password>
              </div>
            
              <br />
              <div class="p-col-12 accept-margin">
                <p-checkbox (onChange)="legalAccepted($event)" binary="true" inputId="binary"></p-checkbox>
                <a [routerLink]="['/legal']"> {{ 'I accept the general terms and conditions' | translate }}</a>
              </div>
              <br />
              <button pButton class="button" type="button" label="Register" (click)="buttonRegisterClicked()" style="margin-bottom: 20px;"
                [disabled]='!canRegister() || !isLegalAccepted'></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>