<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
    <div *ngIf="tooltip">
      <mat-icon [pTooltip]="tooltip">info</mat-icon>
    </div>
  </span>
  <div class="grid answersGrid">
    <div class="col-12">
      <div class="grid answersGrid tableHeader">
        <div class="col md">
          <span *ngIf="columnHeader != null">{{ columnHeader }}</span>
        </div>
        <div style="width: 25px">
          <!-- for tooltip -->
          <span></span>
        </div>
        <div class="col md tableHeader" *ngFor="let column of orderedColumnNames; index as i">
          <div fxLayout="row">
            <span [innerHTML]="column"></span>
            <div *ngIf="columnTooltips[i]">              
              <mat-icon [pTooltip]="columnTooltips[i]">info</mat-icon>
            </div>
          </div>
        </div>
        <div class="col md tableHeader" *ngIf="showRowSum">
          <span>{{ 'Sum' | translate }}</span>
        </div>
      </div>
      <div class="col-12" *ngFor="let row of orderedRowNames; index as i">
        <div class="grid answersGrid">
          <div fxLayout="column" fxLayoutAlign="space-around start" class="col md" [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <div style="word-break: break-word;" fxLayout="row" [innerHTML]="row">
              <!-- <span class="word-with-breaks" ></span> -->
            </div>
            <div class="p-col-12" *ngIf="textAnswers[i] != null">
              <input pInputText type="text" class='textAnswer' pInputText [(ngModel)]="textAnswers[i].freeText" (focusout)="answerSelected($event)" [disabled]="readonly"/>
            </div>
          </div>
          <div [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}" class="outer-container">
            <div class="inner-container">
              <mat-icon *ngIf="rowTooltips[i]" [pTooltip]="rowTooltips[i]">info</mat-icon>
              <mat-icon *ngIf="!rowTooltips[i]" hidden="true"></mat-icon>
            </div>
          </div>
          <div fxLayout="column" class="col md" *ngFor="let column of orderedColumnNames; index as j"
            [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <div style="position: relative; width: fit-content; margin: auto 0;" fxLayout="row">
              <p-inputNumber
                *ngIf="providedTextListMatrix[i][j] != DO_NOT_SHOW"
                [ngModel]="providedTextListMatrix[i][j]"
                [locale]="locale"
                [minFractionDigits]="numberOfDigitsMatrix[i][j]"
                [maxFractionDigits]="numberOfDigitsMatrix[i][j]"
                inputStyleClass="width-100"
                styleClass="input-number"
                class="input-number"
                (onBlur)="answerSelected($event, i, j)"
                [disabled]="readonly">
              </p-inputNumber>
              <span class="hint-style">{{hints[i][j]}}</span>
            </div>
              <mat-error class="validationMessage" *ngIf="fieldValidationMessages[i][j]">{{ fieldValidationMessages[i][j] }}</mat-error>
          </div>
          <div *ngIf="showRowSum" class="col md centerContent" [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <span><b>{{ rowSum[i] | number: '': locale }} {{ rowSumSuffix[i] }}</b></span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="grid answersGrid">
          <div class="col md">
            <span *ngIf="showColumnSum"><b>{{ sumText }}</b></span>
          </div>
          <div style="width: 25px">
            <!-- for tooltip -->
            <span></span>
          </div>
          <div class="col md" *ngFor="let column of orderedColumnNames; index as j">
            <span style="margin-left: 10px;" *ngIf="showColumnSum"><b>{{ columnSum[j] | number: '': locale }} {{columnSumSuffix[j]}}</b></span>
            <br *ngIf="showColumnSum">
            <mat-error style="margin-left: 10px;" class="validationMessage" *ngIf="columnSumValidationMessages[j]">{{ columnSumValidationMessages[j] }}</mat-error>
          </div>
          <div class="col md" *ngIf="showRowSum">
            <span *ngIf="showOverallSum"><b>{{ overallSum | number: '': locale }} {{ columnSumSuffix[0] }}</b></span>
          </div>
        </div>
      </div>
      <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
    </div>
  </div>
</div>