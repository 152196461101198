import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionAnswerInstanceResponse, ValidationTypeEnum } from 'src/app/api/models';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBaseComponent } from '../question-base';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-free-text-numerical-question',
  templateUrl: './free-text-numerical-question.component.html',
  styleUrls: ['./free-text-numerical-question.component.scss']
})
export class FreeTextNumericalQuestionComponent extends QuestionBaseComponent implements OnInit, OnDestroy, QuestionComponent {

  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  fieldValidator: Validator;
  fieldValidationMessages: string;

  answerText: string;
  selectedAnswerOptionId;
  showComponent = false;
  maxValue = Number.MAX_VALUE;
  hint: string;

  private answerSavedSubscription: Subscription;
  private lastObservedSuppliersCountObservableSubscription: Subscription;
  private lastObservedSuppliersSpendObservableSubscription: Subscription;

  constructor(private translate: TranslateService,
    languageService: LanguageService) {
    super(languageService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.isPercentage) {
      this.hint = " %";
    }

    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;
    this.answerText = this.questionAnswerInstanceList[0] ? this.questionAnswerInstanceList[0].freeTextAnswer : '';
    this.selectedAnswerOptionId = this.questionData.answerCollection[0].answerOptionCollection[0].id;
    
    if (!this.readonly) {
      this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
        data => {
          if (data.successStatus) {
            if (data.successStatus) {
              this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            }
          }
        });
    }

    if (this.questionData.questionType.name === 'DiverseSuppliersCountFreeTextNumerical') {
      this.surveyStateService.ChangeSuppliersCount(this.answerText);
    } else if (this.questionData.questionType.name === 'DiverseSuppliersSpendFreeTextNumerical') {
      this.surveyStateService.ChangeSuppliersSpend(this.answerText);
    }
    if (this.questionData.questionType.name === 'DiverseSuppliersCountLessThanTextNumerical') {
      this.lastObservedSuppliersCountObservableSubscription = this.surveyStateService.lastObservedSuppliersCountObservable.subscribe(
        v => {
          this.maxValue = v;
          this.answerSelected();
        });
    } else if (this.questionData.questionType.name === 'DiverseSuppliersSpendLessThanFreeTextNumerical') {
      this.lastObservedSuppliersSpendObservableSubscription = this.surveyStateService.lastObservedSuppliersSpendObservable.subscribe(
        v => {
          this.maxValue = v;
          this.answerSelected();
        });
    }

    this.setFieldValidators();
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(parseFloat(n));
  }

  answerSelected() {
    this.fillFieldValidationMessages();

    if (!this.isValid()) {
      this.answerText = null;
      return
    }

    if (this.answerText !== null && this.answerText.length > 0 || this.isNumeric(this.answerText)) {
      if (parseFloat(this.answerText) > this.maxValue) {
        this.showError('It should be lower or equal to ' + this.maxValue);
        this.surveyStateService.deleteQuestionAnswersCommand(this.questionData.id);
      } else {
        let selectedAnswerOptionIdList = [];
        selectedAnswerOptionIdList = [{ AnswerOptionId: this.selectedAnswerOptionId, FreeText: this.answerText }];
        this.surveyStateService.saveQuestionAnswersCommand(
          selectedAnswerOptionIdList,
          this.questionData.id);
      }
    } else {
      this.surveyStateService.deleteQuestionAnswersCommand(this.questionData.id);
    }
    if (this.questionData.questionType.name === 'DiverseSuppliersCountFreeTextNumerical') {
      this.surveyStateService.ChangeSuppliersCount(this.answerText);
    } else if (this.questionData.questionType.name === 'DiverseSuppliersSpendFreeTextNumerical') {
      this.surveyStateService.ChangeSuppliersSpend(this.answerText);
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode !== 44 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showError(message) {
    this.messageService.clear();
    this.messageService.add({ severity: 'error', summary: 'Value too high.', detail: message, sticky: false, life: 4000 });
  }

  ngOnDestroy(): void {
    this.showComponent = false;
    if (!this.readonly) {
      this.answerSavedSubscription.unsubscribe();
    }
    if (this.lastObservedSuppliersCountObservableSubscription) {
      this.lastObservedSuppliersCountObservableSubscription.unsubscribe();
    }
    if (this.lastObservedSuppliersSpendObservableSubscription) {
      this.lastObservedSuppliersSpendObservableSubscription.unsubscribe();
    }
  }

  private fillFieldValidationMessages() {
    if (this.fieldValidator == null) {
      return;
    }

    const value = Number(this.answerText);

    if (this.fieldValidator.greaterThan != null && value < this.fieldValidator.greaterThan) {
      this.fieldValidationMessages = `${this.translate.instant('greaterThanError')} ${this.fieldValidator.greaterThan}`;
    } else if (this.fieldValidator.lessThan != null && value > this.fieldValidator.lessThan) {
      this.fieldValidationMessages = `${this.translate.instant('lessThanError')} ${this.fieldValidator.lessThan}`;
    } else if (this.fieldValidator.equals != null && value !== this.fieldValidator.equals) {
      this.fieldValidationMessages = `${this.translate.instant('equalsError')} ${this.fieldValidator.equals}`;
    } else {
      this.fieldValidationMessages = null;
    }
  }

  private isValid() {
    return this.fieldValidationMessages == null;
  }

  private setFieldValidators() {

    this.questionData.validatorsCollection.forEach((v, index) => {
      if (this.fieldValidator == null) {
        this.fieldValidator = {
          greaterThan: null,
          lessThan: null,
          equals: null
        };
      }

      switch (v.validationType) {
        case ValidationTypeEnum.Max:
          this.fieldValidator.lessThan = v.value;
          break;
        case ValidationTypeEnum.Min:
          this.fieldValidator.greaterThan = v.value;
          break;
      }
    });

    this.fillFieldValidationMessages();
  }
}
