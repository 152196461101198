<div class="p-grid surveyGrid">
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div>
          <div class="registerButtonWrapper">
            <br />
            <div>
              <label class="regLabel" for="email"> {{ 'E-Mail' | translate }} </label>
              <input id="email" type="text" pInputText [(ngModel)]="email">
            </div>
            <br />
            <div fxLayout="column" fxLayoutAlign="space-around center">
              <button pButton class="button" type="button" label="{{ 'Reset password' | translate }}" (click)="buttonLoginClicked()"
                [disabled]='!canSend()'></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>