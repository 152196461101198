<div fxLayout="row" fxLayoutAlign="space-around center" class="p-grid surveyGrid">
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div>
          <div class="registerButtonWrapper">
            <br />
            <div>
              <label class="regLabel" for="email"> {{ 'E-Mail' | translate }} </label>
              <input id="email" type="text" pInputText [(ngModel)]="email">
            </div>
            <br />
            <div>
              <label class="regLabel" for="password"> {{ 'Password' | translate }} </label>
              <p-password id="password" type="text" styleClass="my-password" inputStyleClass="my-password" [(ngModel)]="password" [toggleMask]="true" [feedback]="false"></p-password>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-around center">
              <br>
              <a [routerLink]="['/change-password']"> {{ 'Forgot password?' | translate }} </a>
              <br>
              <a [routerLink]="['/registration']"> {{ 'Register' | translate }} </a>
              <br>
              <button pButton class="button" type="button" label="{{ 'Login' | translate }}" (click)="buttonLoginClicked()"
                [disabled]='!canRegister()'></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>