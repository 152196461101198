<div style="display: flex; justify-content: center; align-items: center;">
  <div id="container">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <b>{{ 'Progress' | translate }}</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class='p-col p-lg-12' *ngFor="let section of Sections">
        <div fxLayout="row" style="margin: 10px;">
          <p-radioButton class="radio" name="sections" [value]="section.section.id" [label]="section.section.text"
            [(ngModel)]="id" (onClick)="radioClicked($event)"></p-radioButton>
          
          <div fxLayout="column" fxLayoutAlign="center center" class="progress-bar">
            <div>{{ section.percentage | percent: '1.0-0' }}</div>
            <mat-progress-bar style="margin: auto; width: 100%" [value]="section.percentage * 100"
              [class.completed-progress-bar]="false"></mat-progress-bar>
          </div>
        </div>
      </div>
      <div class='p-col p-lg-12' style="margin: 10px;">
        <p-radioButton class="radio" name="sections" [value]="SUMMARY_ID" [label]="'Submit' | translate"
          [(ngModel)]="id" (onClick)="radioClicked($event)"></p-radioButton>
      </div>
    </mat-expansion-panel>
  </div>
</div>