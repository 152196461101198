<div class="p-grid surveyGrid">
  <!-- <div class="p-col-md-fixed surveyLeftBorder1 bigScreenOnly"></div>
  <div class="p-col-md-fixed surveyLeftBorder2 bigScreenOnly"></div>
  <div class="p-col-fixed surveyLeftBorder3 bigScreenOnly">
    <div id="container">
      <h1 id='sectionTitleHeader'>The data you provide for the assessment will be collected, stored and handled securely
      </h1>
      <div class='headerLogo'>
        <div class='headerLogoContent bigScreenOnly'>
          <img src="./assets/kearney_white.png" height="25">
        </div>
      </div>
    </div>
  </div> -->
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div class="p-grid">
          <div class="p-col-12">
            <h3>{{ 'General Terms and Conditions' | translate }}</h3>

            <p class="welcomeText1" [innerHTML]="'Download as PDF document' | translate">
            
            <p>
              <a href="https://ttbatchstorageaccount.blob.core.windows.net/publicstorage/General_Terms_and_Conditions_de.pdf" target="_blank">{{ 'pdf1 (German)' | translate }}</a>
            </p>
            <p>
              <a href="https://ttbatchstorageaccount.blob.core.windows.net/publicstorage/General_Terms_and_Conditions.pdf" target="_blank">{{ 'pdf1 (English)' | translate }}</a>
            </p>

            <h3>{{ 'Terms and Conditions for Exhibitors and Sponsors' | translate }}</h3>

            <p class="welcomeText1" [innerHTML]="'Download as PDF document' | translate">
             <p>
              <a href="https://ttbatchstorageaccount.blob.core.windows.net/publicstorage/terms_and_conditions_sponsors.pdf" target="_blank">{{ 'pdf2 (German)' | translate }}</a>
            </p>
            <p>
              <a href="https://ttbatchstorageaccount.blob.core.windows.net/publicstorage/terms_and_conditions_sponsors_en.pdf" target="_blank">{{ 'pdf2 (English)' | translate }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="back-container">
      <button pButton class="button submit-button" type="button" icon="pi pi-home" (click)="homeClicked()">
        &nbsp;{{ 'Home' | translate }}
      </button>
    </div>
  </div>
</div>