<div class="container">
  <div class="table-container">
    <p-checkbox class="cbDontKnow checkbox" [(ngModel)]="excludeKearneyUsers" [binary]="true" (onChange)="toggleExcludeKearneyUsers($event)"
        label="Exclude Kearney users"></p-checkbox>
    <button pButton class="button download-button" label="{{ 'Download' | translate }}" (click)="downloadData()"></button>
    <p-table
      #dt
      [value]="usersData"
      [tableStyle]="{ 'min-width': '50rem' }"
      [globalFilterFields]="['name', 'country.name', 'representative.name', 'status']"
      resizableColumns="true"
      autoLayout="true"
      paginator="true"
      [rowsPerPageOptions]="[10, 20, 30]" 
      [rows]="10"
      >
      <ng-template pTemplate="header">
        <tr>
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
          <!-- <th>Email</th> -->
          <th>Overall percentage</th>
          <th>Submission date</th>
          <th>Actions</th>
        </tr>
        <tr>
          <th>
            <input type="text" pInputText (keyup)="filterChangedInput('firstName', $event.target)">
          </th>
          <th>
            <input type="text" pInputText (keyup)="filterChangedInput('lastName', $event.target)">
          </th>
          <th>
            <input type="text" pInputText (keyup)="filterChangedInput('email', $event.target)">
          </th>
          <th>
          </th>
          <th>
            <p-checkbox class="cbDontKnow checkbox" [binary]="true" (onChange)="filterSubmitted($event)"
              label="Only submitted"></p-checkbox>
          </th>
          <th>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{ user.firstName }}</td>
          <td>{{ user.lastName }}</td>
          <td>{{ user.email }}</td>
          <td style="display: flex; align-items: center; justify-content: space-between;">
            <div fxLayout="row">
              <p style="width: 70px;">{{ user.filledResponsesPercentage.overallPercentage | percent: '1.0-0' }}</p>
              <button *ngIf="surveyStateLoaded" pButton style="margin: auto 0;" class="button info-button" (click)="infoClicked(user)">
                <mat-icon class="info-icon">info</mat-icon>
              </button>
            </div>
          </td>
          <td>{{ user.submissionDate | utcToLocal | date:'yyyy-MM-dd HH:mm' }}</td>
          <td>
            <div style="display: inline-block;">
              <button pButton class="button action-button" (click)="loadQuestionGroups(user)">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              <button pButton class="button action-button" (click)="loadQuestionGroups(user, true)">
                <mat-icon>insert_drive_file</mat-icon>
              </button>
              <button *ngIf="user.submissionDate" pButton class="button action-button" label="Reverse submition" (click)="reverseSubmitionClicked(user)"></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div>
      <ng-container *ngFor="let questionGroup of questionGroups; index as i">
        <div class="pdf">
          <h1 class="sectionTitleHeader">{{sectionTitleHeaders[i]}}</h1>
          <div class="content" >
            <ng-template appQuestionList></ng-template>
            <!-- <ng-template [appQuestionList]="questionGroup" #questionList></ng-template> -->
          </div>
        </div>
      </ng-container>
    </div>

  </div>
</div>