<div class="p-grid surveyGrid">
  <!-- <div class="p-col-md-fixed surveyLeftBorder1 bigScreenOnly"></div>
  <div class="p-col-md-fixed surveyLeftBorder2 bigScreenOnly"></div>
  <div class="p-col-fixed surveyLeftBorder3 bigScreenOnly">
    <div id="container">
      <h1 id='sectionTitleHeader'>The data you provide for the assessment will be collected, stored and handled securely
      </h1>
      <div class='headerLogo'>
        <div class='headerLogoContent bigScreenOnly'>
          <img src="./assets/kearney_white.png" height="25">
        </div>
      </div>
    </div>
  </div> -->
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div class="p-grid">
          <div class="p-col-12">
            <h1>{{ 'Confidentiality agreement' | translate }}</h1>
            
            <span class="welcomeText1 span-p-style" [innerHTML]="'Confidentiality agreement text' | translate">
            </span>
            <span class="span-p-style">
              <a [routerLink]="['/privacy-policy']">{{ 'Privacy Policy' | translate }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="back-container">
      <button pButton class="button submit-button" type="button" icon="pi pi-home" (click)="homeClicked()">
        &nbsp;{{ 'Home' | translate }}
      </button>
    </div>
  </div>
</div>