<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
    <div *ngIf="tooltip">
      <mat-icon [pTooltip]="tooltip">info</mat-icon>
    </div>
  </span>
  <div class="p-grid answersGrid">
    <mat-list *ngIf="uploadedFiles.length > 0">
      <div mat-subheader>{{ 'Already saved files:' | translate }}</div>
      <!-- <p-listbox [options]="uploadedFiles" optionLabel="name" readonly="true" disabled="true"></p-listbox> -->
      <mat-list-item style="width: 400px;" *ngFor="let file of uploadedFiles">
        <mat-icon matListItemIcon>insert_drive_file</mat-icon>
        <div matListItemTitle>{{file.name}}</div>
        <button *ngIf="!readonly" matListItemMeta mat-icon-button (click)="deleteFile(file)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-list-item>
      
    </mat-list>
    <div class="p-col-12">
      <!-- <p-fileUpload name="myfile[]" url="https://www.primefaces.org/cdn/api2/upload.php" (onUpload)="onBasicUploadAuto($event)" [multiple]="true" accept="image/*" maxFileSize="1000000">
        <!- <ng-template pTemplate="toolbar">
            <div class="py-3">Upload 3 Files</div>
        </ng-template> -->
        <!-- <ng-template let-file pTemplate="file">
            <div>Custom UI to display a file</div>
        </ng-template>
        <ng-template pTemplate="content" let-files>
            <div>Additional content.</div>
        </ng-template> ->
      </p-fileUpload> -->
    </div>
    <!-- <p-message severity="warn" text="{{ 'If you upload a new file, the old one will be deleted.' | translate }}"></p-message>   -->
    <p-fileUpload *ngIf="!readonly" #fileUpload name="demo[]" (uploadHandler)="onUpload($event, fileUpload)" customUpload="true" [multiple]="true" maxFileSize="367001600" accept=".zip,.pdf,.doc,.docx,.pptx,.xml,.csv,.jpg,.png">
      <ng-template pTemplate="content">
        <div class="flex align-items-center justify-content-center flex-column">
          <p class="mt-4 mb-0">Max file size is 300 MB. Accepted file extensions: .zip, .pdf, .doc, .docx, .pptx, .xml, .csv, .jpg, .png.</p>
        </div>
      </ng-template>
      
      </p-fileUpload>
    <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
  </div>
</div>