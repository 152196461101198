import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QuestionAnswerInstanceResponse } from 'src/app/api/models';
import { QuestionResponse } from 'src/app/api/models/question-response';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent implements QuestionComponent {

  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;
}
