import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { QuestionAnswerInstanceResponse } from 'src/app/api/models';
import { MessageService, SelectItem } from 'primeng/api';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { Subscription } from 'rxjs';
import { SelectedOptionRequest } from 'src/app/api/models/selected-option-request';

@Component({
  selector: 'app-matrix-dropdown-question',
  templateUrl: './matrix-dropdown-question.component.html',
  styleUrls: ['./matrix-dropdown-question.component.scss']
})
export class MatrixDropdownQuestionComponent implements OnInit, OnDestroy, QuestionComponent {
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  selectedAnswerOptionIdList: { label: string, value: number }[][] = [];
  selectedOptionsForCell: SelectItem[][][] = [];
  orderedColumnNames = [];
  orderedRowNames = [];
  showComponent = false;

  private answerSavedSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    this.prepareData();
    this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
    this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
      data => {
        if (data.successStatus) {
          if (data.successStatus) {
            this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            if (this.showComponent === false) {
            }
          }
        }
      });
  }

  prepareData() {
    this.questionData.answerCollection = this.questionData.answerCollection.sort((a, b) =>
      a.matrixRowId - b.matrixRowId || a.matrixColumnId - b.matrixColumnId);

    this.orderedColumnNames = this.questionData.matrixColumnConfigurationCollection
      .sort(colConfig => colConfig.matrixColumnId).map(colConfig => colConfig.matrixColumnText)
    this.orderedRowNames = this.questionData.matrixRowConfigurationCollection
      .sort(rowConfig => rowConfig.matrixRowId).map(colConfig => colConfig.matrixRowText)
  
    this.orderedRowNames.forEach((rowName, iIndex) => {
      this.selectedAnswerOptionIdList.push(new Array(this.orderedRowNames.length));
      this.selectedOptionsForCell.push(new Array(this.orderedRowNames.length));
      this.orderedColumnNames.forEach((colName, jIndex) => {
        const answerOption = this.questionData.answerCollection
          .filter(a => a.matrixRowId === iIndex + 1 && a.matrixColumnId === jIndex + 1);
        if (answerOption.length === 0) {
          this.selectedAnswerOptionIdList[iIndex][jIndex] = null;
          return;
        }
        const answerOptionIdsInCell = answerOption[0]
          .answerOptionCollection.map(a => a.id);
        var selectedAnswerOptionId = answerOptionIdsInCell.filter(a => this.questionAnswerInstanceList.map(b => b.answerOptionSelectedId).includes(a))[0];
        selectedAnswerOptionId = selectedAnswerOptionId ? selectedAnswerOptionId : 0
        this.selectedOptionsForCell[iIndex][jIndex] = this.getSelectItemAnswerOptionsForCell(iIndex, jIndex);
        var selectedOption = this.selectedOptionsForCell[iIndex][jIndex].find(option => option.value === selectedAnswerOptionId);
        this.selectedAnswerOptionIdList[iIndex][jIndex] = selectedOption ? { label: selectedOption?.label, value: selectedOption?.value} : null;
      });
    });

    var x = this.selectedOptionsForCell;
  }

  getSelectItemAnswerOptionsForCell(i, j): SelectItem<number>[] {
    const options = this.questionData.answerCollection
      .filter(a => a.matrixRowId === i + 1 && a.matrixColumnId === j + 1)[0].answerOptionCollection
      .map(a => ({ label: a.text, value: a.id } as SelectItem<number>));
    const defaultOption = { label: '(select)', value: 0 };
    options.unshift(defaultOption);
    return options;
  }

  answerSelected(event, i, j) {
    const selectedOptionRequestList = [];

    // this.selectedAnswerOptionIdList[i][j] = event.value.value;
    if (this.canSave()) {
      this.selectedAnswerOptionIdList.forEach(e1 => {
        e1.filter(x => x.value > 0).forEach(e => {
          selectedOptionRequestList.push({ AnswerOptionId: e?.value, FreeText: null } as SelectedOptionRequest);
        });
      });
      if (selectedOptionRequestList.length > 0) {
        this.surveyStateService.saveQuestionAnswersCommand(selectedOptionRequestList,
          this.questionData.id);
      }
    } else if (this.shouldDelete()) {
      this.surveyStateService.deleteQuestionAnswersCommand(this.questionData.id);
    }
  }

  canSave() {
    return this.selectedAnswerOptionIdList.filter(a => a.filter(b => b?.value !== 0).length > 0).length > 0;
  }

  shouldDelete() {
    return this.selectedAnswerOptionIdList.reduce((sum, a) => sum + a.reduce(((sum2, b) => b?.value === 0 ? sum2 + 1 : sum2), 0), 0) === 1;
  }

  ngOnDestroy(): void {
    this.answerSavedSubscription.unsubscribe();
  }
}
