// @ts-ignore
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BenchmarkService, RegistrationService } from 'src/app/api/services';
import { BenchmarkResponse } from 'src/app/api/models';
import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexChart,
  ApexXAxis,
  ApexFill,
  ChartComponent,
  ApexStroke,
  ApexMarkers,
  ApexLegend,
  ApexYAxis
} from "ng-apexcharts";
import { ProgressSpinnerService } from 'src/app/services/progress-spinner.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  fill: ApexFill;
  markers: ApexMarkers;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  legend: ApexLegend;
  colors: string[];

};

@Component({
  selector: 'app-benchmark',
  templateUrl: './benchmark.component.html',
  styleUrls: ['./benchmark.component.scss']
})
export class BenchmarkComponent implements OnInit {
  @ViewChild("chart", {static: false}) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public data: BenchmarkResponse;

  public email = '';
  public isLegalAccepted = false;
  public legalIsBlocking = false;
  public sucessfullyContacted = false;
  public isLowWidthScreen = false;

  private  token: string;
  private questionGroupToFilterOut = "We'd like to understand more about your past experience";
  private factoryOfYearScores = [3, 3.6, 3.45, 3.16, 3.1, 3.4]
  private innerWidth: number;

  constructor(
    private benchmarkService: BenchmarkService,
    private registrationService: RegistrationService,
    private progressSpinnerService: ProgressSpinnerService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.token = this.route.snapshot.paramMap.get('token');
    var surveyId = 1;
    this.benchmarkService.apiBenchmarkGet$Json({surveyId, token: this.token})
      .subscribe(d => {
        if (d) {
          this.data = d;
          this.data.benchmark.sort((a,b) => a.questionGroupOrder - b.questionGroupOrder);
          this.data.benchmarkedValue.sort((a,b) => a.questionGroupOrder - b.questionGroupOrder);
          this.drawRadarChart(this.data);
        }        
      })
  }

  drawRadarChart(data: BenchmarkResponse) {
    var singleLabels: string[];
    var labelFontSize = '16px';
    var yAxisFontSize = '14px';
    var titleObject = {};
    var height: string | number;


    if (this.innerWidth < 600) {
      singleLabels = ['1', '2', '3', '4', '5', '6'];
      labelFontSize = '12px';
      yAxisFontSize = '10px';
      this.isLowWidthScreen = true;
      height = 'auto';
    } else {
      singleLabels = data.benchmark.filter(d => d.questionGroup !== this.questionGroupToFilterOut).map(d => d.questionGroup);
      titleObject = {
        text: "IT/OT Benchmark",
        style: {
          fontSize: '22px'
        }
      };
      height = window.innerHeight*0.9;
    }
 
    this.chartOptions = {
      series: [
        {
          name: "Benchmark",
          data: data.benchmark.filter(d => d.questionGroup !== this.questionGroupToFilterOut).map(d => d.score)
        },
        {
          name: "Factory of the Year",
          data: this.factoryOfYearScores
        },
        {
          name: "Your Scores",
          data: data.benchmarkedValue.filter(d => d.questionGroup !== this.questionGroupToFilterOut).map(d => d.score)
        }
      ],
      chart: {
        height: height,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      title: titleObject,
      stroke: {
        width: 2
      },
      fill: {
        opacity: 0.1
      },
      markers: {
        size: 0
      },
      xaxis: {
        categories: singleLabels,
        labels: {
          style: {
            colors: singleLabels.map(d=>'#000'),
            fontSize: labelFontSize, // 16
            fontFamily: 'Helvetica, Arial, sans-serif',
            cssClass: 'apexcharts-labels'
          },
          offsetY: 0,
        } 
      },
      yaxis: {
        min: 0,
        max: 4,
        tickAmount: 8,
        labels: {
          style: {
            colors: ['#000000'],
            fontSize: yAxisFontSize,//14
            fontFamily: 'Raleway Bold, Arial, sans-serif',
          }
        }
      },
      colors: ['#595959', '#7823dc', '#DC291A'],
      legend: {
        fontSize: labelFontSize
      }
    };

  }

  buttonRegisterClicked(): void {
    this.progressSpinnerService.show();
    this.messageService.clear();

    this.registrationService.apiRegistrationTokenPost$Json({token: this.token, email: this.email, surveyId: 1})
    .subscribe(
      d => {
        if(d) {
          this.messageService.add({ severity: 'info', summary: 'Info', detail: this.translate.instant('Successfully registered') + ' ' + + this.email, sticky: false });
          this.sucessfullyContacted = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('Registration error'), sticky: false });
        }
        this.progressSpinnerService.hide();
      }
    );
  }

  canRegister() {
    return this.isProperEmailProvided();
  }

  isProperEmailProvided() {
    const emailRegex = /\S+@\S+\.\S+/;
    return this.email !== null && this.email.length > 0 && emailRegex.test(this.email);
  }
}
