/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthResponse } from '../models/auth-response';
import { AuthTokenResponse } from '../models/auth-token-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAuthUrlauthenticateGet
   */
  static readonly ApiAuthUrlauthenticateGetPath = '/api/Auth/urlauthenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUrlauthenticateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUrlauthenticateGet$Plain$Response(params?: {
    token?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthTokenResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ApiAuthUrlauthenticateGetPath, 'get');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthTokenResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUrlauthenticateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUrlauthenticateGet$Plain(params?: {
    token?: string;
    context?: HttpContext
  }
): Observable<AuthTokenResponse> {

    return this.apiAuthUrlauthenticateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AuthTokenResponse>) => r.body as AuthTokenResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUrlauthenticateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUrlauthenticateGet$Json$Response(params?: {
    token?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthTokenResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ApiAuthUrlauthenticateGetPath, 'get');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthTokenResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUrlauthenticateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUrlauthenticateGet$Json(params?: {
    token?: string;
    context?: HttpContext
  }
): Observable<AuthTokenResponse> {

    return this.apiAuthUrlauthenticateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AuthTokenResponse>) => r.body as AuthTokenResponse)
    );
  }

  /**
   * Path part for operation apiAuthPasswordauthenticateGet
   */
  static readonly ApiAuthPasswordauthenticateGetPath = '/api/Auth/passwordauthenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthPasswordauthenticateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthPasswordauthenticateGet$Plain$Response(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ApiAuthPasswordauthenticateGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthPasswordauthenticateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthPasswordauthenticateGet$Plain(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<AuthResponse> {

    return this.apiAuthPasswordauthenticateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AuthResponse>) => r.body as AuthResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthPasswordauthenticateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthPasswordauthenticateGet$Json$Response(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ApiAuthPasswordauthenticateGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthPasswordauthenticateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthPasswordauthenticateGet$Json(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<AuthResponse> {

    return this.apiAuthPasswordauthenticateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AuthResponse>) => r.body as AuthResponse)
    );
  }

  /**
   * Path part for operation apiAuthTestGet
   */
  static readonly ApiAuthTestGetPath = '/api/Auth/test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthTestGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthTestGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ApiAuthTestGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthTestGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthTestGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.apiAuthTestGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthTestGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthTestGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.ApiAuthTestGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthTestGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthTestGet$Json(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.apiAuthTestGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
