<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="p-grid answersGrid">
    <!-- <ng-container *ngFor="let selectedCategoryAnswerOption of selectedCategoryAnswerOptionList; index as i"> -->
      <div class="p-col-12 p-lg-9">
        <!-- <p-dropdown class="dropDown orderedCategoryDropdownThemeClass" [options]="categoriesList[i]" optionLabel="label"
          [(ngModel)]="selectedCategoryAnswerOptionList[i].answerOptionSelectedId" (onChange)="answerSelected()">
        </p-dropdown> -->
        <p-dropdown [style]="{'min-width':'250px'}" [options]="selectedOptionsForCell" [(ngModel)]="selectedAnswerOptionIdList" optionLabel="label" [disabled]="readonly"
              (onChange)="answerSelected($event)"></p-dropdown>
      </div>
    <!-- </ng-container> -->
  </div>
  <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
</div>