import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { UrlloginService } from 'src/app/services/urllogin.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-end-page1',
  templateUrl: './end-page1.component.html',
  styleUrls: ['./end-page1.component.scss']
})
export class EndPage1Component implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService) { }


  ngOnInit(): void {
    if (this.accountService.getUser().surveyId !== environment.longSurveyId) {
      this.router.navigate(['/unauthorized']);
    }
  }

  analysisButtonClicked(): void {
    var token = this.route.snapshot.paramMap.get('token');
    this.router.navigateByUrl(`benchmark/${token}`);
  }
}
