<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
    <div *ngIf="tooltip">
      <mat-icon [pTooltip]="tooltip">info</mat-icon>
    </div>
  </span>
  <div class="p-grid answersGrid">
    <div class="p-col-12">
      <input type="text" class='textAnswer' pInputText [(ngModel)]="answerText" (focusout)="answerSelected()" [disabled]="readonly"/>
    </div>
    <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
  </div>
</div>