<div class="p-grid grid">
  <div class="p-col-12 p-lg-6" style="margin: 0 auto;margin-bottom: 30px;">
    <div class="p-grid buttons-container">
      <div fxLayout="column" fxLayoutAlign="space-evenly center" class="button-container">
        <div class="p-col-12">
          <div class="p-col-12" style="margin-bottom: 50px">
            <div class="nextButtonWrapper">
              <button pButton class="button" style="min-width: 300px" label="{{ 'Competition 2024' | translate }}" [routerLink]="['/2024-competition']"></button>
            </div>
          </div>

          <div class="nextButtonWrapper">
            <button pButton class="button-gray" style="min-width: 300px" label="{{ 'Competition 2023 (closed)' | translate }}" (click)="redirectToExternalURL('https://www.foy.2023.kearney.com/')"></button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>