import { Component } from '@angular/core';
import { UserService } from '../../api/services';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent {

  guid = null;
  form: FormGroup;

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.guid = this.route.snapshot.paramMap.get('guid');

    this.form = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  buttonClicked() {
    this.userService.apiUserSetNewPasswordPut$Response( { guid: this.guid, password: this.form.controls.password.value }).subscribe(
      data => {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: this.translate.instant('Password has been set successfully.'), sticky: false });
      }, _ => {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('An error occurred while setting new password'), sticky: false });
      });
  }

  isValid() {
    return this.form.valid && this.form.controls.password.value === this.form.controls.confirmPassword.value;
  }
}
