/* tslint:disable */
/* eslint-disable */
export enum ValidationTypeEnum {
  Min = 1,
  Max = 2,
  Equals = 3,
  MatrixColumnSumMin = 4,
  MatrixColumnSumMax = 5,
  MatrixColumnSumEquals = 6
}
