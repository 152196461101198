import { Component, OnInit, HostListener } from '@angular/core';
import { RegistrationService } from '../../api/services';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FOYDataService } from 'src/app/services/foy-data.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  registerClicked = false;
  email = '';
  password = '';
  isLegalAccepted = false;
  legalIsBlocking = false;
  isReadOnlyModeEnabled = environment.readOnlyModeEnabledForSurveyIdList.indexOf(environment.initialSurveyId) >= 0;

  showPassword: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    private messageService: MessageService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private foyDataService: FOYDataService
  ) { }

  ngOnInit() {
    var x = environment.readOnlyModeEnabledForSurveyIdList;
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: any) {
    if (event.keyCode === 39 && this.canRegister()) {
      this.buttonLoginClicked();
    }
  }
  canRegister() {
    return this.registerClicked === false && this.isProperEmailProvided();
  }

  isProperEmailProvided() {
    const emailRegex = /\S+@\S+\.\S+/;
    return this.email !== null && this.email.length > 0 && emailRegex.test(this.email);
  }

  buttonLoginClicked() {

    this.accountService.login(this.email, this.password).pipe(tap(_ => this.foyDataService.init())).subscribe(
      data => {
        if (data.errorMessage == null) {
          this.registerClicked = true;
          this.messageService.add({ severity: 'info', summary: 'Info', detail: this.translate.instant('Successfully logged in'), sticky: false });
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/2024-competition';
          this.router.navigateByUrl(returnUrl);
        } else {
          this.messageService.clear();
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('Login error'), sticky: false });
        }
      }, _ => {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('Login exception'), sticky: false });
      });
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
