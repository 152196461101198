import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanDeactivate } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { SurveyComponent } from './surveys/survey/survey.component';
import { UnauthorisedComponent } from './users/unauthorised/unauthorised.component';
import { ErrorComponent } from './surveys/error/error/error.component';
import { EndPage1Component } from './static/end-page1/end-page1.component';
import { StartPage1Component } from './static/start-page1/start-page1.component';
import { StartPage0Component } from './static/start-page0/start-page0.component';
import { RegistrationComponent } from './users/registration/registration.component';
import { BenchmarkComponent } from './static/benchmark/benchmark/benchmark.component';
import { LoginComponent } from './users/login/login.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SetNewPasswordComponent } from './users/set-new-password/set-new-password.component';
import { SurveySummaryContentComponent } from './surveys/survey-summary-content/survey-summary-content.component';
import { EndOfSurveyComponent } from './surveys/end-of-survey/end-of-survey.component';
import { ImprintComponent } from './static/imprint/imprint.component';
import { ConfidentialityAgreementComponent } from './static/confidentiality-agreement/confidentiality-agreement.component';
import { PrivacyPolicyComponent } from './static/privacy-policy/privacy-policy.component';
import { DataProtectionComponent } from './static/data-protection/data-protection.component';
import { LandingPageComponent } from './static/landing-page/landing-page.component';
import { SurveySubmittedGuard } from './surveys/survey-submitted.guard';
import { UsersPanelComponent } from './admin/users-panel/users-panel.component';

const routes: Routes = [
  { path: 'legal', component: StartPage1Component },
  { path: 'registration', component: RegistrationComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'set-new-password/:guid', component: SetNewPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'ITOTSurvey', component: StartPage0Component, canActivate: [AuthGuard] },
  { path: 'summary', component: SurveySummaryContentComponent, canActivate: [AuthGuard] },
  { path: 'surveys/:token', component: SurveyComponent, canActivate: [AuthGuard, SurveySubmittedGuard] },
  { path: 'unauthorized', component: UnauthorisedComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'l1/:token', component: StartPage0Component, canActivate: [AuthGuard] },
  { path: 'thankyou/:token', component: EndPage1Component, canActivate: [AuthGuard] },
  { path: 'benchmark/:token', component: BenchmarkComponent, canActivate: [AuthGuard] },
  { path: 'end-of-survey', component: EndOfSurveyComponent, canActivate: [AuthGuard] },
  { path: '2024-competition', component: StartPage0Component, canActivate: [AuthGuard] },
  { path: '', component: LandingPageComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'confidentiality-agreement', component: ConfidentialityAgreementComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'data-protection', component: DataProtectionComponent },
  { path: 'users-panel', component: UsersPanelComponent, canActivate: [AuthGuard], data: { isAdminRequired: true } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ]
})
export class AppRoutingModule { }
