import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent } from 'src/app/dialogs/information-dialog/information-dialog.component';
import { PendingChangesService } from '../pending-changes.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  constructor(private translate: TranslateService,
    private pendingChangesService: PendingChangesService
    ) { }

  ngOnInit() { }

  @HostListener('window:beforeunload')
  beforeunload($event: any) {
    
    if (this.pendingChangesService.hasPendingChanges) {
      return false;
    }
    return true;
  }
}
