import { Component } from '@angular/core';
import { UserService } from '../../api/services';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from 'src/app/api/models';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  email = '';

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private translate: TranslateService
  ) { }

  buttonLoginClicked() {
    var language = this.translate.currentLang;

    this.userService.apiUserResetPut$Response( { email: this.email, language: language == "de" ? LanguageEnum.German : LanguageEnum.English }).subscribe(
      data => {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: this.translate.instant('Password has been reset successfully. Email with link has been sent'), sticky: false });
      }, _ => {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('An error occurred while resetting password'), sticky: false });
      });
  }

  canSend() {
    return this.isProperEmailProvided();
  }

  isProperEmailProvided() {
    const emailRegex = /\S+@\S+\.\S+/;
    return this.email !== null && this.email.length > 0 && emailRegex.test(this.email);
  }
}
