import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { SpinnerModule } from 'primeng/spinner';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { ErrorInterceptor } from './auth/error.interceptor';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SurveyComponent } from './surveys/survey/survey.component';
import { SurveyHeaderComponent } from './surveys/survey-header/survey-header.component';
import { SurveyContentComponent } from './surveys/survey-content/survey-content.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { PasswordModule } from 'primeng/password';
import { SingleSelectQuestionComponent } from './questions/single-select-question/single-select-question.component';
import { QuestionListDirective } from './question-list.directive';
import { MultiSelectQuestionComponent } from './questions/multi-select-question/multi-select-question.component';
import { MultiSelectSeriesQuestionComponent } from './questions/multi-select-series-question/multi-select-series-question.component';
import { SingleSelectSeriesQuestionComponent } from './questions/single-select-series-question/single-select-series-question.component';
import { FreeTextQuestionComponent } from './questions/free-text-question/free-text-question.component';
import { CategoryMultiSelectQuestionComponent } from './questions/category-multi-select-question/category-multi-select-question.component';
import { ImageSingleSelectQuestionComponent } from './questions/image-single-select-question/image-single-select-question.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { FreeTextNumericalQuestionComponent } from './questions/free-text-numerical-question/free-text-numerical-question.component';
import { UnauthorisedComponent } from './users/unauthorised/unauthorised.component';
import { ErrorComponent } from './surveys/error/error/error.component';
import { EndPage1Component } from './static/end-page1/end-page1.component';
import { StartPage1Component } from './static/start-page1/start-page1.component';
import { StartPage2Component } from './static/start-page2/start-page2.component';
import { StartPage3Component } from './static/start-page3/start-page3.component';
import { SurveyNavigationComponent } from './surveys/survey-navigation/survey-navigation.component';
import { MatrixDropdownQuestionComponent } from './questions/matrix-dropdown-question/matrix-dropdown-question.component';
import { MatrixNumericalQuestionComponent } from './questions/matrix-numerical-question/matrix-numerical-question.component';
import { CacheInterceptor } from './ie/cache.interceptor';
import { StartPage4Component } from './static/start-page4/start-page4.component';
import { CategoryMultiSelectOrderedQuestionComponent } from './questions/category-multi-select-ordered-question/category-multi-select-ordered-question.component';
import { StartPage0Component } from './static/start-page0/start-page0.component';
import { S2static1Component } from './static/s2/s2static1/s2static1.component';
import { EmailQuestionComponent } from './questions/email-question/email-question.component';
import { InitialPageSelectorComponent } from './static/initial-page-selector/initial-page-selector.component';
import { S2static3Component } from './static/s2/s2static3/s2static3.component';
import { S2static4Component } from './static/s2/s2static4/s2static4.component';
import { S2static5Component } from './static/s2/s2static5/s2static5.component';
import { MatrixAutocompleteQuestionComponent } from './questions/matrix-autocomplete-question/matrix-autocomplete-question.component';
import { LegalComponent } from './static/legal/legal.component';
import { S2static0Component } from './static/s2/s2static0/s2static0.component';
import { RegistrationComponent } from './users/registration/registration.component';
import { Registration2Component } from './users/registration/registration2.component';
import { BenchmarkComponent } from './static/benchmark/benchmark/benchmark.component';
import { MultiLevelComponent } from './questions/multi-level/multi-level.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AngularMaterialModule } from './lib/angular-material.module';
import { MatButtonModule } from '@angular/material/button';
import { InputTextModule } from 'primeng/inputtext';
import { DividerComponent } from './questions/divider/divider.component';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { TextAreaQuestionComponent } from './questions/text-area-question/text-area-question.component';
import { FileUploadQuestionComponent } from './questions/file-upload-question/file-upload-question.component';
import { MatrixMixedQuestionComponent } from './questions/matrix-mixed-question/matrix-mixed-question.component';
import { LoginComponent } from './users/login/login.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SetNewPasswordComponent } from './users/set-new-password/set-new-password.component';
import { MatrixTextQuestionComponent } from './questions/matrix-text-question/matrix-text-question.component';
import { TextQuestionComponent } from './questions/text-question/text-question.component';
import { SurveySummaryContentComponent } from './surveys/survey-summary-content/survey-summary-content.component';
import { EndOfSurveyComponent } from './surveys/end-of-survey/end-of-survey.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ImprintComponent } from './static/imprint/imprint.component';
import { ConfidentialityAgreementComponent } from './static/confidentiality-agreement/confidentiality-agreement.component';
import { PrivacyPolicyComponent } from './static/privacy-policy/privacy-policy.component';
import { DataProtectionComponent } from './static/data-protection/data-protection.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { LandingPageComponent } from './static/landing-page/landing-page.component';
import { InformationDialogComponent } from './dialogs/information-dialog/information-dialog.component';
import { UsersPanelComponent } from './admin/users-panel/users-panel.component';
import { UtcToLocalPipe } from './pipes/utc-to-local-pipe';
import { PercentageDialogComponent } from './admin/percentage-dialog/percentage-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ProgressSpinnerComponent,
    SurveyComponent,
    SurveyHeaderComponent,
    SurveyContentComponent,
    SingleSelectQuestionComponent,
    QuestionListDirective,
    MultiSelectQuestionComponent,
    MultiSelectSeriesQuestionComponent,
    SingleSelectSeriesQuestionComponent,
    FreeTextQuestionComponent,
    CategoryMultiSelectQuestionComponent,
    ImageSingleSelectQuestionComponent,
    FreeTextNumericalQuestionComponent,
    UnauthorisedComponent,
    ErrorComponent,
    EndPage1Component,
    StartPage1Component,
    StartPage2Component,
    StartPage3Component,
    SurveyNavigationComponent,
    MatrixDropdownQuestionComponent,
    MatrixNumericalQuestionComponent,
    StartPage4Component,
    CategoryMultiSelectOrderedQuestionComponent,
    StartPage0Component,
    S2static1Component,
    EmailQuestionComponent,
    InitialPageSelectorComponent,
    S2static3Component,
    S2static4Component,
    S2static5Component,
    MatrixAutocompleteQuestionComponent,
    LegalComponent,
    S2static0Component,
    RegistrationComponent,
    Registration2Component,
    BenchmarkComponent,
    MultiLevelComponent,
    SingleSelectQuestionComponent,
    MultiSelectQuestionComponent,
    SingleSelectSeriesQuestionComponent,
    MultiSelectSeriesQuestionComponent,
    TextAreaQuestionComponent,
    FileUploadQuestionComponent,
    CategoryMultiSelectQuestionComponent,
    ImageSingleSelectQuestionComponent,
    FreeTextNumericalQuestionComponent,
    MatrixDropdownQuestionComponent,
    MatrixNumericalQuestionComponent,
    CategoryMultiSelectOrderedQuestionComponent,
    EmailQuestionComponent,
    MatrixAutocompleteQuestionComponent,
    MultiLevelComponent,
    DividerComponent,
    MatrixMixedQuestionComponent,
    LoginComponent,
    ChangePasswordComponent,
    SetNewPasswordComponent,
    MatrixTextQuestionComponent,
    TextQuestionComponent,
    SurveySummaryContentComponent,
    EndOfSurveyComponent,
    ImprintComponent,
    ConfidentialityAgreementComponent,
    PrivacyPolicyComponent,
    DataProtectionComponent,
    ConfirmDialogComponent,
    LandingPageComponent,
    InformationDialogComponent,
    UsersPanelComponent,
    UtcToLocalPipe,
    PercentageDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ButtonModule,
    CalendarModule,
    TableModule,
    DropdownModule,
    SpinnerModule,
    HttpClientModule,
    SidebarModule,
    MenuModule,
    ScrollPanelModule,
    ToastModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    TooltipModule,
    OverlayPanelModule,
    AutoCompleteModule,
    RadioButtonModule,
    ProgressBarModule,
    CheckboxModule,
    InputNumberModule,
    DividerModule,
    InputTextModule,
    MultiSelectModule,
    ListboxModule,
    NgApexchartsModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    AngularMaterialModule,
    MatButtonModule,
    FileUploadModule,
    PasswordModule,
    MatListModule,
    MessagesModule,
    MessageModule,
    MatProgressBarModule,
    InputTextareaModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  exports: [
    UtcToLocalPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}