<div class="container-fluid dialog-body">
    <mat-dialog-content><span class="message">{{ data.firstName }} {{ data.lastName }}</span></mat-dialog-content>

    <p-table
      #dt
      [value]="data.sections">
      <ng-template pTemplate="header">
        <tr>
          <th>Section</th>
          <th>Percentage</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-section>
        <tr>
          <td>{{ section.text }}</td>
          <td>{{ data.filledResponsesPercentage.percentageByQuestionGroup[section.id] | percent: '1.0-0' }}</td>
        </tr>
      </ng-template>
    </p-table>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
        <button mat-raised-button color="alert" (click)="dialogRef.close(false)">{{'Ok' | translate}}</button>
    </mat-dialog-actions>
</div>