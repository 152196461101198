import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UrlloginService } from 'src/app/services/urllogin.service';
import { RegistrationService } from 'src/app/api/services';
import { ProgressSpinnerService } from 'src/app/services/progress-spinner.service';
import { MessageService } from 'primeng/api';
import { Subject, throwError } from 'rxjs';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  email = '';
  pin = '';
  isLegalAccepted = false;
  legalIsBlocking = false;
  isReadOnlyModeEnabled = environment.readOnlyModeEnabledForSurveyIdList.indexOf(environment.longSurveyId) >= 0;

  token = '';
  stopSubscription$ = new Subject();

  constructor(
    private accountService: AccountService,
    private router: Router
    ) { }

  ngOnInit() {
    // if (this.accountService.getUser().surveyId !== environment.longSurveyId) {
    //   this.router.navigate(['/unauthorized']);
    // }
  }

  redirectToExternalURL(url: string): void {
    window.location.href = url;
  }
}
