import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UrlloginService } from '../services/urllogin.service';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.accountService.userValue;
        
        if (!user) {
            // not authorised
            this.accountService.removeUser();
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }

        var isAdminRequired = route.data["isAdminRequired"];
        if (isAdminRequired && !user.isAdmin) {
            return false;
        }
        
        return true;
    }
}
