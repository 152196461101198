<div class="p-grid surveyGrid">
  <!-- <div class="p-col-md-fixed surveyLeftBorder1 bigScreenOnly"></div>
  <div class="p-col-md-fixed surveyLeftBorder2 bigScreenOnly"></div>
  <div class="p-col-fixed surveyLeftBorder3 bigScreenOnly">
    <div id="container">
      <h1 id='sectionTitleHeader'>The data you provide for the assessment will be collected, stored and handled securely
      </h1>
      <div class='headerLogo'>
        <div class='headerLogoContent bigScreenOnly'>
          <img src="./assets/kearney_white.png" height="25">
        </div>
      </div>
    </div>
  </div> -->
  <div class="p-col surveyQuestions">
    <div id="container">
      <div class="content">
        <div class="p-grid">
          <div class="p-col-12">
            <h1>{{ 'Imprint' | translate }}</h1>
            <h3>{{ 'Competition' | translate }}</h3>
            <p class="welcomeText1" [innerHTML]="'Address' | translate">

            <p class="welcomeText1" [innerHTML]="'Commercial registration' | translate"></p>

            <p class="welcomeText1" [innerHTML]="'Managing Board' | translate"></p>

            <h3>{{ 'Conference' | translate }}</h3>

            <p class="welcomeText1" [innerHTML]="'Conference-text-1' | translate"></p>
            <p class="welcomeText1" [innerHTML]="'Conference-text-2' | translate"></p>
            <p class="welcomeText1" [innerHTML]="'Conference-text-3' | translate"></p>

            <h3>{{ 'Platform' | translate }}</h3>
            <p class="welcomeText1" [innerHTML]="'Platform-text-3' | translate"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="back-container">
      <button pButton class="button submit-button" type="button" icon="pi pi-home" (click)="homeClicked()">
        &nbsp;{{ 'Home' | translate }}
      </button>
    </div>
  </div>
</div>