<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="levels">
    <div class="level" *ngFor="let answer of questionData.answerCollection" (click)="labelClicked(answer.answerOptionCollection[0].id)"
    [(ngModel)]="selectedAnswerOptionId"  ngDefaultControl [ngClass] = "answer.answerOptionCollection[0].id === selectedAnswerOptionId ? 'active' : 'not-active'">
      {{answer.answerOptionCollection[0].text.substring(3,10)}}
    </div>
  </div>
  <div class="p-grid answersGrid">
    <div class="p-col-12" *ngFor="let answer of questionData.answerCollection">
      <div id="answerBox">
        <label class='answerLabel' [for]="questionData.id+'_'+answer.id" [innerHTML]='answer.answerOptionCollection[0].text'
        [ngClass] = "answer.answerOptionCollection[0].id === selectedAnswerOptionId ? 'label-active' : 'label-not-active'"
        ></label>
        <input
          *ngIf="answer.answerOptionCollection[0].isFreeText && answer.answerOptionCollection[0].id===selectedAnswerOptionId"
          type="text" class='textAnswer' pInputText [(ngModel)]="selectedAnswerOptionFreeText" />
      </div>
    </div>
  </div>
  <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
</div>