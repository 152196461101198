import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PendingChangesService {

  hasPendingChanges = false;
  constructor() { }

  setHasPendingChanges(hasPendingChanges: boolean) {
    this.hasPendingChanges = hasPendingChanges;
  }
}
