import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { AnswerOptionResponse, AnswerResponse, LanguageEnum, QuestionAnswerInstanceResponse, ValidationTypeEnum, ValidatorResponse } from 'src/app/api/models';
import { MessageService, SelectItem } from 'primeng/api';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { Subscription } from 'rxjs';
import { SelectedOptionRequest } from 'src/app/api/models/selected-option-request';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { FreeTextRowAnswer } from '../free-text-row-answer';

interface Validator {
  greaterThan: number;
  lessThan: number;
  equals: number;
}

interface CellAnswer {
  answer: AnswerResponse,
  answerOption: AnswerOptionResponse
}


@Component({
  selector: 'app-matrix-text-question',
  templateUrl: './matrix-text-question.component.html',
  styleUrls: ['./matrix-text-question.component.scss']
})
export class MatrixTextQuestionComponent implements OnInit, OnDestroy, QuestionComponent {
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;
  @Input() readonly: boolean;

  providedTextListMatrix: string[][] = [];
  columnSum: number[] = [];
  rowSum: number[] = [];

  fieldValidator: Validator[][] = [];
  fieldValidationMessages: string[][] = [];

  columnSumValidators: Validator[] = [];
  columnSumValidationMessages: string[] = [];

  textAnswers: FreeTextRowAnswer[] = [];
  orderedColumnNames = [];
  orderedRowNames = [];
  showComponent = false;
  hints: string[][] = [];

  columnHeader: string;
  showColumnSum: boolean;
  showRowSum: boolean;
  isPercentage: boolean;
  numberOfDigits: number;

  DO_NOT_SHOW = 'DO_NOT_SHOW';

  locale: string;
  locales: { [key in LanguageEnum] : string } = {
    [LanguageEnum.English]: 'en-US',
    [LanguageEnum.German]: 'de-DE',
  }

  private answerSavedSubscription: Subscription;
  constructor(private translate: TranslateService,
    private languageService: LanguageService) { }

  ngOnInit() {
    this.prepareData();
    
    this.showColumnSum = this.questionData.matrixConfiguration?.showColumnSum ?? false;
    this.showRowSum = this.questionData.matrixConfiguration?.showRowSum ?? false;
    this.columnHeader = this.questionData.matrixConfiguration?.columnHeader;

    this.textAnswers = new Array(this.orderedRowNames.length);
    this.questionData.matrixRowConfigurationCollection.filter(config => config.freeTextAnswer).forEach((row, index) => {
      const answerOptionId = row.freeTextAnswer.answerOptionCollection[0].id;
      const questionAnswerInstanceForFreeText = this.questionAnswerInstanceList.filter(a => a.answerOptionSelectedId === answerOptionId)[0];
      const freeText = questionAnswerInstanceForFreeText != null ? questionAnswerInstanceForFreeText.freeTextAnswer : '';
      this.textAnswers[row.matrixRowId - 1] = {
        freeText: freeText,
        answerOptionId: answerOptionId
      };
    });

    this.showComponent = this.surveyStateService?.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id) ?? true;
    if (!this.readonly) {
      this.answerSavedSubscription = this.surveyStateService.answerSaved.subscribe(
        data => {
          if (data.successStatus) {
            this.showComponent = this.surveyStateService.checkVisibilityCondition(this.showComponent, this.questionData.dependsOnAnswerOptionIdCollection, this.questionData.id);
            if (this.showComponent === false) {
            }
          }
        });
    }

    this.locale = this.locales[this.languageService.language];
    this.languageService.languageChanged$.subscribe(lang => {
      this.locale = this.locales[lang];
    });
  }

  getAnswerForCell(rowIndex, columnIndex) : CellAnswer {
    var answer = this.questionData.answerCollection.filter(a => a.matrixRowId === rowIndex + 1 && a.matrixColumnId === columnIndex + 1);
    if (answer == null || answer.length === 0) {
      return null;
    }
    return {
      answer: answer[0],
      answerOption: answer[0].answerOptionCollection[0]
    };
  }

  prepareData() {
    this.questionData.answerCollection = this.questionData
      .answerCollection
      .filter(a => a.matrixRowId != null && a.matrixColumnId != null) // filter out answers which are not in matrix (are row input text) 
      .sort((a, b) =>
      a.matrixRowId - b.matrixRowId || a.matrixColumnId - b.matrixColumnId);

    // this.orderedColumnNames = this.questionData.answerCollection.map(item => item.matrixColumnText)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    // this.orderedRowNames = this.questionData.answerCollection.map(item => item.matrixRowText)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    this.orderedColumnNames = this.questionData.matrixColumnConfigurationCollection.sort((a, b) => a.matrixColumnId - b.matrixColumnId).map(colConfig => colConfig.matrixColumnText)
    this.orderedRowNames = this.questionData.matrixRowConfigurationCollection.sort((a, b) => a.matrixRowId - b.matrixRowId).map(colConfig => colConfig.matrixRowText)

    this.orderedRowNames.forEach((rowName, iIndex) => {
      this.providedTextListMatrix.push(new Array(this.orderedRowNames.length));
      this.hints.push(new Array(this.orderedRowNames.length));
      this.orderedColumnNames.forEach((colName, jIndex) => {
        const answers = this.getAnswerForCell(iIndex, jIndex);
        const answerOptionIdInCell = answers?.answerOption;
        const answer = answers?.answer;

        if (answerOptionIdInCell != null) {
          const questionAnswerInstanceForCell = this.questionAnswerInstanceList.filter(a => a.answerOptionSelectedId === answerOptionIdInCell.id)[0];
          const responseForAnswerOptionIdInCellText = questionAnswerInstanceForCell != null ? questionAnswerInstanceForCell.freeTextAnswer : '';
          this.providedTextListMatrix[iIndex][jIndex] = responseForAnswerOptionIdInCellText;

          this.hints[iIndex][jIndex] = answer?.hint != null ? ' ' + answer?.hint : '';
        } else {
          this.providedTextListMatrix[iIndex][jIndex] = this.DO_NOT_SHOW;
        }
      });
    });
    this.calculateSumTable();
    this.setColumnSumValidators();
    this.setFieldValidators();
  }

  calculateSumTable() {
    this.columnSum = new Array(this.orderedColumnNames.length).fill(0);
    this.rowSum = new Array(this.orderedRowNames.length).fill(0);
    for (let iIndex = 0; iIndex < this.orderedRowNames.length; iIndex++) {
      for (let jIndex = 0; jIndex < this.orderedColumnNames.length; jIndex++) {
        const cellValue = this.getCellNumberValue(iIndex, jIndex);
        this.columnSum[jIndex] += cellValue;
        this.rowSum[iIndex] += cellValue;
      };
    };
  }

  private getCellNumberValue(rowIndex, columnIndex) {
    return this.providedTextListMatrix[rowIndex][columnIndex] != null && this.providedTextListMatrix[rowIndex][columnIndex] != this.DO_NOT_SHOW ? Number(this.providedTextListMatrix[rowIndex][columnIndex]) : 0;
  }

  answerSelected(event) {
    this.calculateSumTable();
    this.fillColumnSumValidationMessages();
    this.fillFieldValidationMessages();
    const selectedOptionRequestList = [];
    this.orderedRowNames.forEach((_, iIndex) => {
      this.orderedColumnNames.forEach((__, jIndex) => {
        var answers = this.getAnswerForCell(iIndex, jIndex);
        if (answers?.answerOption != null) {
          selectedOptionRequestList
            .push({ AnswerOptionId: answers.answerOption.id, FreeText: this.providedTextListMatrix[iIndex][jIndex] } as SelectedOptionRequest);
        }
      });
    });

    this.textAnswers.forEach((textAnswer, index) => {
      selectedOptionRequestList
        .push({ AnswerOptionId: textAnswer.answerOptionId, FreeText: textAnswer.freeText } as SelectedOptionRequest);
    });

    this.surveyStateService.saveQuestionAnswersCommand(selectedOptionRequestList, this.questionData.id);
  }

  ngOnDestroy(): void {
    if (!this.readonly) {
      this.answerSavedSubscription.unsubscribe();
    }
  }

  private fillColumnSumValidationMessages() {
    this.columnSumValidationMessages = new Array(this.orderedColumnNames.length).fill(null);

    this.columnSumValidators.forEach((v, index) => {
      if (v == null) {
        return;
      }
      var sum = this.columnSum[index];
      if (v.greaterThan != null && sum < v.greaterThan) {
        this.columnSumValidationMessages[index] = `${this.translate.instant('greaterThanError')} ${v.greaterThan}`;
      } else if (v.lessThan != null && sum > v.lessThan) {
        this.columnSumValidationMessages[index] = `${this.translate.instant('lessThanError')} ${v.lessThan}`;
      } else if (v.equals != null && sum !== v.equals) {
        this.columnSumValidationMessages[index] = `${this.translate.instant('equalsError')} ${v.equals}`;
      }
    });
  }

  private fillFieldValidationMessages() {
    this.fieldValidationMessages = new Array(this.orderedRowNames.length).fill(new Array(this.orderedColumnNames.length).fill(null));

    this.fieldValidator.forEach((rows, rowIndex) => {

      rows.forEach((v, columnIndex) => {
        if (v == null) {
          return;
        }
        const cellValue = this.getCellNumberValue(rowIndex, columnIndex);

        if (v.greaterThan != null && cellValue < v.greaterThan) {
          this.fieldValidationMessages[rowIndex][columnIndex] = `${this.translate.instant('greaterThanError')} ${v.greaterThan}`;
        } else if (v.lessThan != null && cellValue > v.lessThan) {
          this.fieldValidationMessages[rowIndex][columnIndex] = `${this.translate.instant('lessThanError')} ${v.lessThan}`;
        } else if (v.equals != null && cellValue !== v.equals) {
          this.fieldValidationMessages[rowIndex][columnIndex] = `${this.translate.instant('equalsError')} ${v.equals}`;
        }
      })
    });
  }

  private isColumnSumValidator(validator: ValidatorResponse) {
    return validator.matrixColumnId != null && validator.matrixRowId == null
  }

  private isFieldValidator(validator: ValidatorResponse) {
    return validator.matrixColumnId != null && validator.matrixRowId != null
  }

  private setColumnSumValidators() {
    this.columnSumValidators = new Array(this.orderedColumnNames.length).fill(null);
    this.columnSumValidationMessages = new Array(this.orderedColumnNames.length).fill(null);

    this.questionData.validatorsCollection.filter(this.isColumnSumValidator).forEach((v, index) => {

      var arrayIndex = v.matrixColumnId - 1;
      if (this.columnSumValidators[arrayIndex] == null) {
        this.columnSumValidators[arrayIndex] = {
          greaterThan: null,
          lessThan: null,
          equals: null
        };
      }

      switch (v.validationType) {
        case ValidationTypeEnum.MatrixColumnSumMax:
          this.columnSumValidators[arrayIndex].lessThan = v.value;
          break;
        case ValidationTypeEnum.MatrixColumnSumMin:
          this.columnSumValidators[arrayIndex].greaterThan = v.value;
          break;
        case ValidationTypeEnum.MatrixColumnSumEquals:
          this.columnSumValidators[arrayIndex].equals = v.value;
          break;
      }
    });

    this.fillColumnSumValidationMessages();
  }

  private setFieldValidators() {
    this.fieldValidator = new Array(this.orderedRowNames.length).fill(new Array(this.orderedColumnNames.length).fill(null));
    this.fieldValidationMessages = new Array(this.orderedRowNames.length).fill(new Array(this.orderedColumnNames.length).fill(null));

    this.questionData.validatorsCollection.filter(this.isFieldValidator).forEach((v, index) => {

      var columnIndex = v.matrixColumnId - 1;
      var rowIndex = v.matrixRowId - 1;

      if (this.fieldValidator[rowIndex][columnIndex] == null) {
        this.fieldValidator[rowIndex][columnIndex] = {
          greaterThan: null,
          lessThan: null,
          equals: null
        };
      }

      switch (v.validationType) {
        case ValidationTypeEnum.MatrixColumnSumMax:
          this.fieldValidator[rowIndex][columnIndex].lessThan = v.value;
          break;
        case ValidationTypeEnum.MatrixColumnSumMin:
          this.fieldValidator[rowIndex][columnIndex].greaterThan = v.value;
          break;
        case ValidationTypeEnum.MatrixColumnSumEquals:
          this.fieldValidator[rowIndex][columnIndex].equals = v.value;
          break;
      }
    });

    this.fillFieldValidationMessages();
  }
}
