import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-end-of-survey',
  templateUrl: './end-of-survey.component.html',
  styleUrls: ['./end-of-survey.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EndOfSurveyComponent {
  
}
