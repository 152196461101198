<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="p-grid answersGrid">
    <div>
      <div style="position: relative; width: fit-content; margin: auto 0;" fxLayout="row">
        <p-inputNumber 
          class='textAnswer'
          [(ngModel)]="answerText"
          [disabled]="readonly"
          (focusout)="answerSelected()"
          [locale]="locale"
          [minFractionDigits]="numberOfDigits"
          [maxFractionDigits]="numberOfDigits" />
          <span class="hint-style">{{ hint }}</span>
        </div>
        <mat-error class="validationMessage" *ngIf="fieldValidationMessages">{{ fieldValidationMessages }}</mat-error>
    </div>
    <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
  </div>
</div>