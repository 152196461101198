import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../api/services';
// import { environment } from 'src/environment';
// import { LoginService } from '../api/services';
// import { ApiHelperService } from './api-helper.service';

// export interface ChangePasswordData {
//   userToken: string;
//   password: string;
// }

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  isAdmin: boolean;
  surveyToken: string;
  surveyId: number;
  isSubmitted: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public userSubject$: BehaviorSubject<User | null>;
  // public user: Observable<User | null>;

  // private changePasswordData: ChangePasswordData;

  constructor(
      private router: Router,
      private loginService: AuthService,
      // private apiHelperService: ApiHelperService
  ) {
    const user = this.getUser();
    this.userSubject$ = new BehaviorSubject(user);
  }

  public get userValue() {
    return this.userSubject$.value;
  }

  // public get changeDataUserToken() {
  //   return this.changePasswordData ? this.changePasswordData.userToken : null;
  // }

  login(email: string, password: string) {
    return this.loginService.apiAuthPasswordauthenticateGet$Json({ email: email,
      password: password
    }).pipe(map(res => {
      const user = {
        id: res.id,
        firstName: res.firstName,
        lastName: res.lastName,
        email: email,
        token: res.token,
        isAdmin: res.isAdmin,
        surveyToken: res.surveyToken,
        surveyId: res.surveyId,
        isSubmitted: res.isSurveyFinished
      };
      // if (res.shouldChangePassword) {
      //   this.changePasswordData = {
      //     userToken: userToken,
      //     password: password
      //   }
      // }
      if (res.isAuthSuccessful /* && !res.shouldChangePassword */) {
        this.setUser(user);
        this.userSubject$.next(user);
      }
      return res;
    }));
  }

  // changePassword(newPassword: string) {
  //   return this.loginService.apiLoginChangePasswordPost$Json({ body:{
  //     oldPassword: this.changePasswordData.password,
  //     newPassword: newPassword,
  //     userToken: this.changePasswordData.userToken
  //   }}).pipe(map(res => {
  //     const user = {
  //       id: res.id,
  //       userToken: this.changePasswordData.userToken,
  //       token: res.token,
  //       isAdmin: res.isAdmin
  //     };
  //     if (res.isAuthSuccessful) {
  //       this.setUser(user);
  //       this.userSubject$.next(user);
  //     }
  //     return res;
  //   }));
  // }

  logout() {
    this.router.navigate(['/login']).then(() => {
      // this.changeLogoutDate();
      this.removeUser();
      this.userSubject$.next(null);
    });
  }

  // changeLogoutDate() {
  //   this.loginService.apiLoginPut$Json({
  //     body: {
  //       userToken: this.userValue.userToken,
  //       date: this.apiHelperService.getDateFormatToApi(new Date(Date.now()))
  //     }
  //   }).subscribe();
  // }

  getUser() {
    const userJSON = localStorage.getItem('user');
    const user: User = userJSON ? JSON.parse(userJSON) : null;
    return user;
  }

  setUser(user: User) {
    localStorage.setItem("token", user.token);
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("token");
    localStorage.removeItem('user');
  }

  setLanguage(language: string) {
    localStorage.setItem('language', language);
  }

  getLanguage() {
    var storageLanguage = localStorage.getItem('language');
    return storageLanguage ?? 'en';
  }

  markSurveyAsSubmitted() {
    var user = this.getUser();
    user.isSubmitted = true;
    this.userSubject$.next(user);
    localStorage.setItem('user', JSON.stringify(user));
  }
}
