import { Component, Input, OnInit } from "@angular/core";
import { LanguageEnum, QuestionResponse } from "../api/models";
import { LanguageService } from "../services/language.service";

@Component({
  template: ''
})
export class QuestionBaseComponent implements OnInit {
  @Input() questionData: QuestionResponse;
  isPercentage: boolean;
  numberOfDigits: number;

  locale: string;
  locales: { [key in LanguageEnum] : string } = {
    [LanguageEnum.English]: 'en-US',
    [LanguageEnum.German]: 'de-DE',
  }
  
  constructor(private languageService: LanguageService) {}

  ngOnInit() {
    this.isPercentage = this.questionData.numberInputConfiguration?.type == "%"
    this.numberOfDigits = this.questionData.numberInputConfiguration?.numberOfDigits ?? 0;

    this.locale = this.locales[this.languageService.language];
    this.languageService.languageChanged$.subscribe(lang => {
      this.locale = this.locales[lang];
    });
  }
}