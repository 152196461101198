import { Component, OnInit, HostListener, Input, OnDestroy } from '@angular/core';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UrlloginService } from 'src/app/services/urllogin.service';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-survey-navigation',
  templateUrl: './survey-navigation.component.html',
  styleUrls: ['./survey-navigation.component.scss']
})

export class SurveyNavigationComponent implements OnInit, OnDestroy {
  @Input() NextButtonVisible = true;
  @Input() PrevButtonVisible = true;
  @Input() marginBottom: number;
  surveyStateServiceSubscription;
  questionGroupLoadedSubscription;
  progess: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surveyStateService: SurveyStateService,
    private messageService: MessageService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.surveyStateServiceSubscription = this.surveyStateService.surveyInitialised.subscribe(
      data => {
        if (data === true) {
          this.refreshProgress();
        }
      }
    );

    this.questionGroupLoadedSubscription = this.surveyStateService.questionGroupLoaded.subscribe(
      data => {
        if (data === null) {
          this.surveyStateService.initSurveyCommand().subscribe();
        }
      });

    this.surveyStateService.responsesPercentagesChanged.subscribe(progress => this.progess = this.surveyStateService.GetProgress());
  }

  buttonNextClicked() {
    const token = this.route.snapshot.paramMap.get('token');
    if (this.accountService.getUser().surveyId === environment.longSurveyId) {
      if (this.router.url.startsWith('/l1')) {
        this.router.navigate(['/l2', token]);
      } else if (this.router.url.startsWith('/l2')) {
        this.router.navigate(['/surveys', token]);
      } else if (/*this.surveyStateService.AreAllQuestionsOnTheCurrentGroupAnswered().length > 0*/ false) {
        // this.showError('Please answer all the questions on the page.\n Questions with missing answers: ' + this.surveyStateService.AreAllQuestionsOnTheCurrentGroupAnswered().join(' '));
        this.surveyStateService.GoToNextQuestionGroup();
      } else if (!this.surveyStateService.IsLastQuestionGroupVisible()) {
        this.surveyStateService.GoToNextQuestionGroup();
      } else {
        this.surveyStateService.GoToSummary()
        // this.surveyStateService.loadQuestionsForTheCurrentQuestionGroupCommand();
        // this.router.navigate(['/benchmark', token]);
      }
    }
    this.refreshProgress();
    window.scroll(0, 0);
  }

  buttonPreviousClicked() {
    const token = this.route.snapshot.paramMap.get('token');
    if (this.accountService.getUser().surveyId === environment.longSurveyId) {
      if (this.router.url.startsWith('/l2')) {
        this.router.navigate(['/l1', token]);
      } else if (this.router.url.startsWith('/benchmark')) {
        this.router.navigate(['/surveys', token]);
      } else if (this.isOnSummaryPage()) {
        this.surveyStateService.GoToLastQuestionGroup();
      } else if (!this.surveyStateService.IsFirstQuestionGroupVisible()) {
        this.surveyStateService.GoToPreviousQuestionGroup();
      } else {
        this.surveyStateService.loadQuestionsForTheCurrentQuestionGroupCommand();
      }
    } 

    this.refreshProgress();
    window.scroll(0, 0);
  }

  refreshProgress() {
    this.progess = this.surveyStateService.GetProgress();
  }

  showError(message) {
    this.messageService.clear();
    this.messageService.add({ severity: 'error', summary: 'Answers required', detail: message, sticky: false, life: 4000 });
  }

  showInfo(message) {
    this.messageService.clear();
    this.messageService.add({ severity: 'info', summary: 'Info', detail: message, sticky: false, life: 4000 });
  }

  ngOnDestroy(): void {
    this.surveyStateServiceSubscription.unsubscribe();
    this.questionGroupLoadedSubscription.unsubscribe();
  }

  isSubmitButtonVisible() {
    return !this.isOnSummaryPage();
  }

  submitClicked() {
    this.surveyStateService.GoToSummary();
  }

  private isOnSummaryPage() {
    return this.router.url.includes('summary');
  }
}
