<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="p-grid answersGrid">
    <div class="p-col-12" *ngFor="let answer of questionData.answerCollection; index as i">
      <div id="answerBox">
        <p-checkbox [id]="answer.answerOptionCollection[0].id" [name]="answer.answerOptionCollection[0].id"
          [value]="answer.answerOptionCollection[0].id" [(ngModel)]="selectedAnswerOptionIdList"
          (onChange)=toggleSelected(answer.answerOptionCollection[0].id)></p-checkbox>
        <label (click)="labelClicked(answer.answerOptionCollection[0].id)" class='answerLabel'
          [for]="answer.answerOptionCollection[0].id" [innerHTML]='answer.answerOptionCollection[0].text'></label>
        <input
          *ngIf="answer.answerOptionCollection[0].isFreeText && selectedAnswerOptionIdList.indexOf(answer.answerOptionCollection[0].id)>-1"
          type="text" class='textAnswer' pInputText
          [(ngModel)]="selectedAnswerOptionTextCollection.Item(answer.answerOptionCollection[0].id).value"
          (focusout)="toggleSelected(answer.answerOptionCollection[0].id)" />
      </div>
    </div>
  </div>
  <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
</div>