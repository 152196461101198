<div class="questionWrapper" *ngIf="showComponent">
    <span class='questionHeader'>
        <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
        <span class='questionText' [innerHTML]="questionData.text"></span>
    </span>
    <div class="p-grid answersGrid">
        <div class="p-col-12">
            <p-multiSelect class="multiSelectAnswers" [options]="categoryList"
                [(ngModel)]="selectedCategoryAnswerOptionIdList" (onChange)="answerSelected()"></p-multiSelect>
        </div>
        <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
    </div>
</div>