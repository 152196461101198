import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AccountService } from "../services/account.service";


@Injectable({ providedIn: 'root' })
export class SurveySubmittedGuard  {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.accountService.getUser();
        if (!user.isSubmitted) {
          return true;
        }
        this.router.navigate(['/end-of-survey']);
        return false;
    }
}
