<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="p-grid answersGrid">
    <div class="p-col-12">
      <textarea pInputTextarea [(ngModel)]="answerText" (focusout)="answerSelected()" [rows]="numberOfRows" [disabled]="readonly"></textarea>
    </div>
    <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
  </div>
</div>