<div class="p-grid surveyGrid">
  <!-- <div class="p-col-md-fixed surveyLeftBorder1 bigScreenOnly"></div>
  <div class="p-col-md-fixed surveyLeftBorder2 bigScreenOnly"></div>
  <div class="p-col-fixed surveyLeftBorder3 bigScreenOnly">
    <div id="container">
      <h1 id='sectionTitleHeader'>The data you provide for the assessment will be collected, stored and handled securely
      </h1>
      <div class='headerLogo'>
        <div class='headerLogoContent bigScreenOnly'>
          <img src="./assets/kearney_white.png" height="25">
        </div>
      </div>
    </div>
  </div> -->
  <div class="p-col surveyQuestions">
    
    <div id="container">
      <div class="content">
        <div class="p-grid">
          <div class="p-col-12">
            <h1>{{ 'Introduction' | translate }}</h1>
            <h3>{{ 'Overview' | translate }}</h3>
            <p class="welcomeText1" [innerHTML]="'Introduction 1' | translate">

            <h3>{{ 'Program' | translate }}</h3>
            <p>{{ 'Elements' | translate }}</p>
            <ul class="list">
              <li>{{ 'Element 1' | translate }}</li>
              <li>{{ 'Element 2' | translate }}</li>
              <li>{{ 'Element 3' | translate }}</li>
              <li>{{ 'Element 4' | translate }}</li>
              <li>{{ 'Element 5' | translate }}</li>
            </ul>
          </div>
          <div>
            <h3>{{ 'Participation' | translate }}</h3>
            <ul class="list">
              <li>{{ 'Participation 1' | translate }}</li>
              <li>{{ 'Participation 2' | translate }}</li>
            </ul>
            <h3>{{ 'Sponsorship' | translate }}</h3>
            <p>{{ 'Sponsorship text' | translate }}</p>
            <h3>{{ 'Final Word' | translate }}</h3>
            <p>{{ 'Final Word text' | translate }}</p>
          </div>
          
        </div>
        
        <!-- <div class="registerButtonWrapper">
          <button pButton class="registerButton" type="button" label="{{ 'Register' | translate }}" (click)="buttonRegisterClicked()"
                    [disabled]='!isLegalAccepted'></button>
        </div> -->
      </div>
    </div>
    <div class="back-container">
      <button pButton class="button submit-button" type="button" icon="pi pi-arrow-left" (click)="backClicked()">
        &nbsp;{{ 'Back' | translate }}
      </button>
    </div>
  </div>
</div>
