<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="p-grid answersGrid">
    <div class="p-col-12">
      <div class="p-grid answersGrid">
        <div class="p-col p-md-4">
        </div>
        <div class="p-col p-md answerCell" *ngFor="let column of orderedColumnNames">
          <span [innerHTML]="column"></span>
        </div>
      </div>
      <div class="p-col-12" *ngFor="let row of orderedRowNames; index as i">
        <div class="p-grid answersGrid">
          <div class="p-col p-md-4">
            <span [innerHTML]="row"></span>
          </div>
          <div class="p-col p-md answerCell" *ngFor="let column of orderedColumnNames; index as j">
            <p-checkbox [name]="getOptionsForCell(i + 1, j + 1).id" [value]="getOptionsForCell(i + 1, j + 1).id"
              [label]="getOptionsForCell(i + 1, j + 1).text" [(ngModel)]="selectedAnswerOptionIdList"
              (onChange)=toggleSelected($event)></p-checkbox>
          </div>
        </div>
      </div>
      <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
    </div>