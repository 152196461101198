<div id="container" class="p-col surveyQuestions">
  <div style="margin-bottom: 40px">
    <h3 class="sectionTitleHeader" innerHTML="{{ 'Submit info' | translate }}"></h3>
  </div>

  <div fxLayoutAlign="center center">
    <button pButton class="button-gray button-width" type="button" label="{{ 'Generate PDF' | translate }}" (click)="generatePdf()"></button>
  </div>

  <div style="display: none;">
    <ng-container  *ngFor="let questionGroup of questionGroups; index as i">
      <div class="pdf">
        <h1 class="sectionTitleHeader">{{sectionTitleHeaders[i]}}</h1>
        <div class="content" >
          <ng-template appQuestionList></ng-template>
          <!-- <ng-template [appQuestionList]="questionGroup" #questionList></ng-template> -->
        </div>
      </div>
    </ng-container>
  </div>
    
  <div style="margin-top: 50px" fxLayoutAlign="center center">
    <button pButton class="button button-width" type="button" label="{{ 'Submit' | translate }}" (click)="submitClicked()"></button>
  </div>

  <app-survey-navigation marginBottom="120" [NextButtonVisible]="false"></app-survey-navigation>
</div>