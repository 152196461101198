<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="grid answersGrid">
    <div class="col-12">
      <div class="grid answersGrid tableHeader">
        <div class="col md">
        </div>
        <div class="col md tableHeader" *ngFor="let column of orderedColumnNames">
          <span [innerHTML]="column"></span>
        </div>
      </div>
      <div class="col-12" *ngFor="let row of orderedRowNames; index as i">
        <div class="grid answersGrid">
          <div class="col md" [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <span [innerHTML]="row"></span>
          </div>
          <div class="col md answerCell" *ngFor="let column of orderedColumnNames; index as j"
            [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <p-dropdown [options]="selectedOptionsForCell[i][j]" [(ngModel)]="selectedAnswerOptionIdList[i][j]" optionLabel="label"
              (onChange)="answerSelected($event, i, j)"></p-dropdown>
          </div>
        </div>
      </div>
      <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
    </div>
  </div>
</div>