<div id="mainDiv">
    <mat-toolbar style="margin-top: 30px; background-color: white" fxLayout="row">
      <div fxLayoutAlign="start start" >
        <img src="./assets/foy_logo.png" style="margin-left: 50px;cursor: pointer;" height="100" [routerLink]="['/2024-competition']">
      </div>

      <div style="width: 100%; margin-right: 20px" fxLayoutAlign="end center">
        <div *ngIf="isUserLoggedIn" class="name-container">
          <b>{{ 'Welcome text' | translate }}, {{user.firstName}} {{user.lastName}}</b>
        </div>

        <button mat-button [matMenuTriggerFor]="belowMenu" style="margin-right: 10px;" class="menu-font">
          <b>{{ 'Language: ' | translate }}</b>
          <mat-icon>arrow_drop_down</mat-icon>
            <b class="language">{{translate.currentLang}}</b>
        </button>
        <mat-menu #belowMenu="matMenu" yPosition="below">
          <button *ngFor="let lang of translate.getLangs()" (click)="changeLanguage(lang)" mat-menu-item>{{ lang }}</button>
        </mat-menu>


        <div *ngIf="isUserLoggedIn">
          <p-toast></p-toast>
          <p-menu #menu [model]="items" [popup]="true"></p-menu>
          <button pButton class="my-button" type="button" (click)="menu.toggle($event)" icon="pi pi-bars"></button>
        </div>
      </div>
    </mat-toolbar>

    <div [ngClass]="leftSidebarVisible?'outletPositionWide':'outletPositionNarrow'">
      <router-outlet></router-outlet>
      <app-progress-spinner></app-progress-spinner>
      <p-toast position="top-right"></p-toast>
      <div class="footer-container" >
        <div style="height: 100%" fxLayout="row" fxLayoutAlign="space-around center" >
          <a class="a-style"> {{ 'Ⓒ Fabrik des Jahres | All rights reserved' | translate }} </a>
          <a class="a-style" [routerLink]="['/imprint']"> {{ 'Imprint' | translate }} </a>
          <a class="a-style" [routerLink]="['/confidentiality-agreement']"> {{ 'Confidentiality agreement' | translate }} </a>
          <a class="a-style" [routerLink]="['/data-protection']"> {{ 'Data protection' | translate }} </a>
          <a class="a-style" [routerLink]="['/privacy-policy']"> {{ 'T&Cs' | translate }} </a>
        </div>
      </div>
    </div>

</div>