import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UrlloginService } from 'src/app/services/urllogin.service';
import { RegistrationService } from 'src/app/api/services';
import { ProgressSpinnerService } from 'src/app/services/progress-spinner.service';
import { MessageService } from 'primeng/api';
import { Subject, throwError } from 'rxjs';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-start-page0',
  templateUrl: './start-page0.component.html',
  styleUrls: ['./start-page0.component.scss']
})
export class StartPage0Component implements OnInit {
  email = '';
  pin = '';
  isLegalAccepted = false;
  legalIsBlocking = false;
  isReadOnlyModeEnabled = environment.readOnlyModeEnabledForSurveyIdList.indexOf(environment.longSurveyId) >= 0;

  token = '';
  stopSubscription$ = new Subject(); 

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private registrationService: RegistrationService,
    private progressSpinnerService: ProgressSpinnerService,
    private messageService: MessageService
    ) { }

  ngOnInit() {
    // if (this.accountService.getUser().surveyId !== environment.longSurveyId) {
    //   this.router.navigate(['/unauthorized']);
    // }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: any) {
    if (event.keyCode === 39) {
      this.buttonStartClicked();
    }
  }

  buttonStartClicked() {
      const user = this.accountService.getUser();
      // this.router.navigateByUrl(`l2/${user.surveyToken}`);
      if (user.isSubmitted) {
        this.router.navigate(['/end-of-survey']);
      } else {
        this.router.navigateByUrl(`surveys/${user.surveyToken}`);
      }
  }

  GenerateGuuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
