import { Component, OnInit, OnDestroy } from '@angular/core';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { SurveySectionGetResponse } from 'src/app/api/models/survey-section-get-response';
import { GetFilledResponsesPercentageResponse, QuestionGroupResponse } from 'src/app/api/models';

interface SurveySection {
  section: SurveySectionGetResponse;
  percentage: number;
}

@Component({
  selector: 'app-survey-header',
  templateUrl: './survey-header.component.html',
  styleUrls: ['./survey-header.component.scss']
})
export class SurveyHeaderComponent implements OnInit, OnDestroy {
  public CurrentQuestionGroup: QuestionGroupResponse;
  public Sections: SurveySection[];
  public ActiveSection: SurveySectionGetResponse = {};
  public id: number;
  public SUMMARY_ID = -1;
  surveyStateServiceSubscription;
  constructor(private surveyStateService: SurveyStateService) { }

  ngOnInit() {
    this.surveyStateServiceSubscription = this.surveyStateService.surveyInitialised.subscribe(
      data => {
        if (data === true) {
          var sections = this.surveyStateService.GetSurveySections();
          var filledResponsesPercentage = this.surveyStateService.getFilledResponsesPercentage();
          this.setSections(sections, filledResponsesPercentage);
        }
      }
    );
    this.surveyStateService.questionGroupLoaded.subscribe(
      data => {
        if (data === true) {
          this.ActiveSection = this.surveyStateService.GetActiveSurveySection();
          this.CurrentQuestionGroup = this.surveyStateService.GetCurrentQuestionGroup();
          this.id = this.ActiveSection.id;
        }
      }
    );
    this.surveyStateService.responsesPercentagesChanged.subscribe(
      filledResponsesPercentage => {
        var sections = this.surveyStateService.GetSurveySections();
        this.setSections(sections, filledResponsesPercentage);
      }
    );
  }

  private setSections(sections: SurveySectionGetResponse[], filledResponsesPercentage: GetFilledResponsesPercentageResponse) {
    this.Sections = [];
    for (var section of sections) {
      this.Sections.push({
        section: section,
        percentage: filledResponsesPercentage.percentageByQuestionGroup[section.id]
      });
    }
  }

  ngOnDestroy(): void {
    this.surveyStateServiceSubscription.unsubscribe();
  }

  radioClicked(ev) {
    var value = ev.value;
    if (value == this.SUMMARY_ID) {
      this.surveyStateService.GoToSummary();
    } else {
      this.surveyStateService.GoToQuestionGroup(value);
    }
  }
}
