import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { AnswerOptionResponse, AnswerResponse, LanguageEnum, QuestionAnswerInstanceResponse, ValidationTypeEnum } from 'src/app/api/models';
import { MessageService, SelectItem } from 'primeng/api';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { Subscription } from 'rxjs';
import { SelectedOptionRequest } from 'src/app/api/models/selected-option-request';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { FreeTextRowAnswer } from '../free-text-row-answer';

interface ColumnSumValidator {
  greaterThan: number;
  lessThan: number;
  equals: number;
}



interface CellAnswer {
  answer: AnswerResponse,
  answerOption: AnswerOptionResponse
}

@Component({
  selector: 'app-text-question',
  templateUrl: './text-question.component.html',
  styleUrls: ['./text-question.component.scss']
})
export class TextQuestionComponent implements QuestionComponent {
  @Input() readonly: boolean;
  @Input() questionData: QuestionResponse;
  @Input() questionAnswerInstanceList: QuestionAnswerInstanceResponse[];
  @Input() messageService: MessageService;
  @Input() surveyStateService: SurveyStateService;

}
