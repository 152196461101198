import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';
import { AccountService, User } from './services/account.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { validateBasis } from '@angular/flex-layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'Surveys';
  topSidebarVisible = true;
  leftSidebarVisible = false;
  version = 'Kearney DCoE - version 1.0.0.0';
  headerText = 'Surveys';
  screenWidth;
  items: MenuItem[] | undefined;
  isUserLoggedIn: boolean;
  user: User;

  constructor(public translate: TranslateService,
    private languageService: LanguageService,
    private accountService: AccountService,
    private router: Router) {

    translate.addLangs(['en', 'de']);
    var language = this.accountService.getLanguage();
    translate.setDefaultLang(language);
    translate.use(language);

    this.languageService.changeLanguage(this.languageService.getLanguageEnum(language));
  }

  ngOnInit() {
    this.detectScreenSize();

    this.loadMenu();

    this.user = this.accountService.getUser();
    this.isUserLoggedIn = this.user != null;
    
    this.accountService.userSubject$.subscribe(val => {
      this.isUserLoggedIn = val != null;
      this.user = val;
    })
  }

  loadMenu() {
    this.items = [
      {
        label: this.translate.instant('Main'),
        icon: 'pi pi-home',
        command: () => {
          this.router.navigate(['/2024-competition']);
        }
      },
      {
        label: this.translate.instant('Questionnaire'),
        icon: 'pi pi-pencil',
        command: () => {
          const user = this.accountService.getUser();
          if (user.isSubmitted) {
            this.router.navigate(['/end-of-survey']);
          } else {
            this.router.navigateByUrl(`surveys/${user.surveyToken}`);
          }
        }
      },
      {
          label: this.translate.instant('Introduction'),
          icon: 'pi pi-book',
          command: () => {
            this.router.navigate(['/legal']);
          }
      },
      {
          label: this.translate.instant('Sign out'),
          icon: 'pi pi-sign-out',
          command: () => {
            this.singOut();
          }
      },
    ];
  }

  isBigScreen() {
    return window.innerWidth > 680;
  }
  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  public changeLanguage(lang) {
    console.log('changeLanguage: ' + lang);
    this.translate.use(lang);
    this.loadMenu();
    this.languageService.changeLanguage(this.languageService.getLanguageEnum(lang));
    this.accountService.setLanguage(lang);
  }

  singOut() {
    this.accountService.logout();
  }
}