<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="p-grid answersGrid">
    <div class="p-col-12 p-md-6 p-lg-6 p-xl-2"
      *ngFor="let answer of questionData.answerCollection[0].answerOptionCollection">
      <a (click)="answerSelected(answer.id)">
        <img [src]="answer.imageUrl"
          [ngClass]="answer.id === selectedAnswerOptionId ? 'selectedImageWithText' : 'imageWithText'">
        <div [innerHTML]="answer.text"></div>
      </a>
    </div>
  </div>
</div>