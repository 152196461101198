<div class="p-grid surveyGrid">
  <div class="p-col-md-fixed surveyLeftBorder1 bigScreenOnly"></div>
  <div class="p-col-md-fixed surveyLeftBorder2 bigScreenOnly"></div>
  <div class="p-col-fixed surveyLeftBorder3 bigScreenOnly">
      <div id="container">
          <h1 id='sectionTitleHeader'>
            Thank you for completing the IT/OT Assessment Survey 
          </h1>
          <div class='headerLogo '>
              <div class='headerLogoContent'>
                  <img src="./assets/kearney_white.png" height="25">
              </div>
          </div>
      </div>
  </div>
  <div class="p-col surveyQuestions">
      <div id="container">
          <div class="content">
            <div id="chart" class = "apexchart">
              <ng-container *ngIf="chartOptions">
                <apx-chart 
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [xaxis]="chartOptions.xaxis"
                  [yaxis]="chartOptions.yaxis"
                  [stroke]="chartOptions.stroke"
                  [markers]="chartOptions.markers"
                  [fill]="chartOptions.fill"
                  [title]="chartOptions.title"
                  [legend]="chartOptions.legend"
                  [colors]="chartOptions.colors"
                ></apx-chart>
                <div *ngIf="isLowWidthScreen">
                  <h4>Question Groups</h4>
                  <ul class = "question-groups">
                    <li>1. Digital technology adoption</li>
                    <li>2. Production</li>
                    <li>3. Material planning & logistics</li>
                    <li>4. Maintenance</li>
                    <li>5. Quality</li>
                    <li>6. People development & Training</li>
                  </ul>
                </div>
                
              </ng-container>
            </div>
            <div class = "right-section">
              <h3>This benchmark provides a first indication of your state of IT/OT digitalization.
                <br><br>
                If you would like to discuss the details of this survey or explore further deep dives for your organization 
                please complete the email text field below and we will be in touch - <span class = "stratOps">Kearney, StratOps team</span>
              </h3>
              <div *ngIf = "!sucessfullyContacted"class="registerButtonWrapper">
                <br />
                <div class="emailWrapper">
                  <label class="regLabel" for="email">E-mail</label>
                  <input id="email" type="text" pInputText [(ngModel)]="email">
                </div>
                <br />
                <div class = "btn">
                  <button pButton class="contact-us-btn" type="button" label="Contact Us!" (click)="buttonRegisterClicked()"
                  [disabled]='!canRegister()'></button>
                </div>
            </div>
            <div *ngIf = "sucessfullyContacted" class = "contacted">
              <img src = "../../../../assets/success.svg" height="50px">
              <h4 style = "margin-left: 10px;">Successfully contacted</h4>
            </div>
          </div>
      </div>
  </div>
</div>