import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { UrlloginService } from 'src/app/services/urllogin.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  isLegalAccepted = false;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private location: Location) { }

  ngOnInit() {
  }

  homeClicked() {
    this.router.navigate(['/2024-competition']);
  }
}
