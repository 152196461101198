<div class="questionWrapper" *ngIf="showComponent">
  <span class='questionHeader'>
    <span class='questionNumberText' [innerHTML]="questionData.numberText"></span>
    <span class='questionText' [innerHTML]="questionData.text"></span>
  </span>
  <div class="grid answersGrid">
    <div class="col-12">
      <div class="grid answersGrid tableHeader">
        <div class="col md">
          <span *ngIf="columnHeader != null">{{ columnHeader }}</span>
        </div>
        <div class="col md tableHeader" *ngFor="let column of orderedColumnNames">
          <span [innerHTML]="column"></span>
        </div>
        <div class="col md tableHeader" *ngIf="showRowSum">
          <span>{{ 'Sum' | translate }}</span>
        </div>
      </div>
      <div class="col-12" *ngFor="let row of orderedRowNames; index as i">
        <div class="grid answersGrid">
          <div fxLayout="column" fxLayoutAlign="space-around start" class="col md" [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <span [innerHTML]="row"></span>
            <div class="p-col-12" *ngIf="textAnswers[i] != null">
              <input pInputText type="text" class='textAnswer' pInputText [(ngModel)]="textAnswers[i].freeText" (focusout)="answerSelected()" [disabled]="readonly"/>
            </div>
          </div>
          <div class="col md" *ngFor="let column of orderedColumnNames; index as j"
            [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <input pInputText *ngIf="providedTextListMatrix[i][j] != DO_NOT_SHOW" [(ngModel)]="providedTextListMatrix[i][j]" [locale]="locale" [suffix]="hints[i][j]" [disabled]="readonly"
              (focusout)="answerSelected()"/>
          </div>
          <div *ngIf="showRowSum" class="col md centerContent" [ngClass]="{'tableRow': i%2 === 0,'tableAlternateRow': i%2 !== 0}">
            <span>{{ rowSum[i] | number: '': locale }}</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="grid answersGrid">
          <div class="col md">
            <span *ngIf="showColumnSum">{{ 'Sum' | translate }}</span>
          </div>
          <div class="col md" *ngFor="let column of orderedColumnNames; index as j">
            <span *ngIf="showColumnSum">{{ columnSum[j] | number: '': locale }}</span>
            <br *ngIf="showColumnSum">
            <mat-error class="validationMessage" *ngIf="columnSumValidationMessages[j]">{{ columnSumValidationMessages[j] }}</mat-error>
          </div>
          <div class="col md" *ngIf="showRowSum">
          </div>
        </div>
      </div>
      <span class="questionFooter" [innerHTML]="questionData.footerText"></span>
    </div>
  </div>
</div>