import { Component, OnInit, Input, OnDestroy, ViewChild, QueryList, ViewChildren, ComponentFactoryResolver } from '@angular/core';
import { QuestionComponent } from 'src/app/question.component';
import { SurveyStateService } from 'src/app/services/surveyState.service';
import { FilterMatchMode, MessageService, SelectItem } from 'primeng/api';
import { forkJoin, of, Subscription } from 'rxjs';
import { QuestionAnswerGetResponse, QuestionAnswerInstanceResponse, QuestionGroupResponse, SurveySectionGetResponse, UserSurveyDataResponse } from 'src/app/api/models';
import { QuestionResponse } from 'src/app/api/models/question-response';
import { SelectedOptionRequest } from 'src/app/api/models/selected-option-request';
import { QuestionAnswerService, UsersPanelService } from 'src/app/api/services';
import { ProgressSpinnerService } from 'src/app/services/progress-spinner.service';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { PercentageDialogComponent, PercentageDialogData } from '../percentage-dialog/percentage-dialog.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { saveAs } from 'file-saver';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { QuestionListDirective } from 'src/app/question-list.directive';
import { getQuestions } from 'src/app/surveys/questions-helper';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { InformationDialogComponent } from 'src/app/dialogs/information-dialog/information-dialog.component';
import * as html2pdf from 'html2pdf.js';
import { FOYDataService } from 'src/app/services/foy-data.service';

@Component({
  selector: 'app-users-panel',
  templateUrl: './users-panel.component.html',
  styleUrls: ['./users-panel.component.scss']
})
export class UsersPanelComponent implements OnInit {

  @ViewChild("dt") dt: Table;
  
  @ViewChildren(QuestionListDirective) questionList: QueryList<QuestionListDirective>;
  questionGroups: QuestionGroupResponse[];
  sectionTitleHeaders: string[] = [];

  private kearneyEmailDomain = "@kearney.com"

  usersData: UserSurveyDataResponse[];

  excludeKearneyUsers = false;

  surveyStateLoaded = false;
  sections: SurveySectionGetResponse[];
  displayQuestionnaire = false;

  constructor(private usersPanelService: UsersPanelService,
    private surveyStateService: SurveyStateService,
    private progressSpinnerService: ProgressSpinnerService,
    private questionAnswerService: QuestionAnswerService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private componentFactoryResolver: ComponentFactoryResolver,
    private messageService: MessageService,
    private translate: TranslateService,
    private foyDataService: FOYDataService
  ) { }

  ngOnInit() {
    this.loadUsers();

    this.surveyStateService.surveyInitialised.subscribe(loaded => {
      this.surveyStateLoaded = loaded;
      if (loaded) {
        this.sections = this.surveyStateService.GetSurveySections();
      }
    });

    this.questionGroups = this.surveyStateService.getQuestionGroupsInOrder();
  }

  private loadUsers() {
    this.progressSpinnerService.show();
    this.usersPanelService.apiUsersPanelGet$Json().subscribe(usersData => {
      this.usersData = usersData;
      this.progressSpinnerService.hide();
    });
  }

  toggleExcludeKearneyUsers(event) {
    this.excludeKearneyUsers = event.checked;
    if (this.excludeKearneyUsers) {
      // Workaround for filtering not null values
      this.dt.filter(this.kearneyEmailDomain, 'email', FilterMatchMode.NOT_CONTAINS);
    } else {
      // reset submitted filter
      this.dt.filter(null, 'email', null);
    }
  }

  
  filterChangedInput(property, target) {
    this.dt.filter(target.value, property, 'contains');
  }

  infoClicked(user: UserSurveyDataResponse) {
    var data: PercentageDialogData = {
      filledResponsesPercentage: user.filledResponsesPercentage,
      firstName: user.firstName,
      lastName: user.lastName,
      sections: this.sections
    };

    this.dialog.open(PercentageDialogComponent, {
      disableClose: false,
      data: data
    });
  }

  reverseSubmitionClicked(user: UserSurveyDataResponse) {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      data: {
        message: `Are you sure you want to reverse submition for ${user.firstName} ${user.lastName}?`
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.usersPanelService.apiUsersPanelPut({
          userId: user.id
        }).subscribe(_ => this.loadUsers());
      }
    });
  }

  filterSubmitted(event) {
    var val = event.checked;
    if (val) {
      // Workaround for filtering not null values
      this.dt.filter('-', 'submissionDate', FilterMatchMode.CONTAINS);
    } else {
      // reset submitted filter
      this.dt.filter(null, 'submissionDate', null);
    }
  }

  downloadData() {
    this.progressSpinnerService.show();

    this.usersPanelService
      .apiUsersPanelExcelGet()
      .pipe(
        finalize(() => this.progressSpinnerService.hide())
      )
      .subscribe(fileBlob => {
        const currentDate = new Date();
        const shortDate = currentDate.toLocaleDateString('de-DE');
        saveAs(fileBlob, `users_data_${shortDate}.xlsx`);
      });
  }

  private loadQuestionGroups(user: UserSurveyDataResponse, generatePdf: boolean = false) {
    this.displayQuestionnaire = false;
    var questionGroupsRequest = this.questionGroups.length > 0 ? of(this.questionGroups) : this.surveyStateService.initSurveyCommand();

    questionGroupsRequest.pipe(
      tap(data => this.questionGroups = this.surveyStateService.getQuestionGroupsInOrder()),
      concatMap(() => {
        return this.foyDataService.updateCurrencyForUser(user.id);
      }),
      concatMap(() => {
        const tasks$ = [];
        this.questionGroups.forEach(qg => {
          tasks$.push(this.questionAnswerService
            .apiQuestionAnswerGetAnswersForSurveyGet$Json({
              questionGroupId: qg.id,
              surveyInstanceId: user.surveyInstanceId,
              language: this.languageService.language
            }))
        });
        return forkJoin(tasks$);
      })
    ).subscribe((responses: QuestionAnswerGetResponse[]) => {
      this.LoadQuestionnaire(responses);
      if (generatePdf) {
        this.generatePdf(user.id.toString());
      }
      this.progressSpinnerService.hide()
      this.displayQuestionnaire = true;
    }, _ => {
      this.progressSpinnerService.hide()
    });
  }

  private LoadQuestionnaire(questionsGroups: QuestionAnswerGetResponse[]) {
    this.sectionTitleHeaders = [];
    var surveySections = this.surveyStateService.GetSurveySections();

    this.questionList.forEach((question, index) => {
      question.viewContainerRef.clear();
      const viewContainerRef = question.viewContainerRef;

      var questionsInGroup = getQuestions(questionsGroups[index]);
      this.sectionTitleHeaders.push(surveySections[index].text);
      questionsInGroup.forEach(question => {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(question.component);
        const componentRef = viewContainerRef.createComponent(componentFactory);
        (componentRef.instance as QuestionComponent).questionData = question.questionData;
        (componentRef.instance as QuestionComponent).questionAnswerInstanceList = question.questionAnswerInstanceList;
        (componentRef.instance as QuestionComponent).messageService = this.messageService;
        (componentRef.instance as QuestionComponent).readonly = true;
      });
    });
  }

  generatePdf(name: string) {
    var elements = document.querySelectorAll<HTMLElement>('.pdf');

    var array = Array.from(elements);
    // array = array.slice(0, -1);
    var content = this.combineElements(array);

    const options = {
      margin: 10,
      filename: `${name}.pdf`,
      pagebreak: { before: 'app-matrix-numerical-question' },
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait', pagesplit: false },
    };

    var dialogRef = this.dialog.open(InformationDialogComponent, {
      disableClose: false,
      data: {
        message: this.translate.instant('PDF is being generated. This may take a few seconds')
      }
    });

    setTimeout(() => this.downloadPDF(array, options), 100);
  }

  private downloadPDF(elements, options) {
    let worker = html2pdf()
      .set(options)
      .from(elements[0])
  
    if (elements.length > 1) {
      worker = worker.toPdf() // worker is now a jsPDF instance
  
      elements.slice(1).forEach((element, index) => {
        worker = worker
          .get('pdf')
          .then(pdf => {
            pdf.addPage()
          })
          .from(element)
          .toContainer()
          .toCanvas()
          .toPdf()
      })
    }

    worker = worker.save()
  }

  private combineElements(elements: HTMLElement[]): HTMLElement {
    const combinedElement = document.createElement('div');

    elements.forEach(element => {
      combinedElement.appendChild(element.cloneNode(true));
    });

    return combinedElement;
  }
}
