import { Injectable } from '@angular/core';
import { QuestionAnswerGetResponse } from '../api/models/question-answer-get-response';
import { SurveyService, QuestionAnswerService, FilesService, UserService } from '../api/services';
import { forkJoin, BehaviorSubject, Subject, Observable } from 'rxjs';
import { SurveyGetResponse } from '../api/models/survey-get-response';
import { ProgressSpinnerService } from './progress-spinner.service';
import { SurveySectionGetResponse } from '../api/models/survey-section-get-response';
import { GetFilledResponsesPercentageResponse, LanguageEnum, QuestionAnswerPostResponse, QuestionGroupResponse, SurveyInstanceDataResponse, UploadedFileResponse } from '../api/models';
import { UrlloginService } from './urllogin.service';
import { SelectedOptionRequest } from '../api/models/selected-option-request';
import { SurveyInstanceDataService } from '../api/services/survey-instance-data.service';
import { mergeMap, tap } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from './account.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { FOYData } from './foy-data';
import { FOYDataService } from './foy-data.service';
import { PendingChangesService } from '../surveys/pending-changes.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyStateService {
  private Survey: SurveyGetResponse;
  private QuestionGroupsInOrder: QuestionGroupResponse[] = [];
  private CurrentQuestionGroup: QuestionGroupResponse;
  private CurrentSection: SurveySectionGetResponse;
  private QuestionAnswersForTheCurrentQuestionGroup: QuestionAnswerGetResponse;
  private AllQuestionAnswersInstanceOptionIdSelectedList = [];
  private surveyInitialisedSubject = new BehaviorSubject<boolean>(null);
  private questionGroupLoadedSubject = new BehaviorSubject<boolean>(null);
  private answerSavedSubject = new Subject<QuestionAnswerPostResponse>();
  private responsesPercentagesChangedSubject = new Subject<GetFilledResponsesPercentageResponse>();
  private getFilledResponsesPercentageResponse: GetFilledResponsesPercentageResponse;
  public surveyInitialised = this.surveyInitialisedSubject.asObservable();
  public questionGroupLoaded = this.questionGroupLoadedSubject.asObservable();
  public answerSaved = this.answerSavedSubject.asObservable();
  public responsesPercentagesChanged = this.responsesPercentagesChangedSubject.asObservable();
  public isSaving = false;
  public isForwardDirection = true;

  private lastObservedSuppliersCountSubject = new BehaviorSubject<number>(Number.MAX_VALUE);
  public lastObservedSuppliersCountObservable = this.lastObservedSuppliersCountSubject.asObservable();
  public lastObservedSuppliersCountValue = Number.MAX_VALUE;

  private lastObservedSuppliersSpendSubject = new BehaviorSubject<number>(Number.MAX_VALUE);
  public lastObservedSuppliersSpendObservable = this.lastObservedSuppliersSpendSubject.asObservable();
  public lastObservedSuppliersSpendValue = Number.MAX_VALUE;

  constructor(
    private surveyService: SurveyService,
    private accountService: AccountService,
    private questionAnswerService: QuestionAnswerService,
    private progressSpinnerService: ProgressSpinnerService,
    private surveyInstanceDataService: SurveyInstanceDataService,
    private languageService: LanguageService,
    private filesService: FilesService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translate: TranslateService,
    private foyDataService: FOYDataService,
    private pendingChangesService: PendingChangesService
  ) {
    this.languageService.languageChanged$.subscribe(lang => {
      this.initSurveyCommand().subscribe();
    });

    // Reload data when user is changed
    this.accountService.userSubject$.subscribe(_ => {
      this.initSurveyCommand().subscribe();
    })
  }

  initSurveyCommand() {
    var currentQuestionGroup = +this.route.snapshot.queryParams['currentQuestionGroup'];
    this.progressSpinnerService.show();
    const tasks$ = [];
    tasks$.push(this.surveyService.apiSurveyGet$Json({language: this.languageService.language }));
    tasks$.push(this.questionAnswerService.apiQuestionAnswerGetFilledResponsesPercentageGet$Json());

    return forkJoin(tasks$)
      .pipe(tap((responses: any[]) => {
        this.progressSpinnerService.hide();
        this.Survey = responses[0];
        this.AllQuestionAnswersInstanceOptionIdSelectedList = responses[0].allQuestionAnswersInstanceOptionIdSelectedList;
        this.CreateOrderedQuestionGroupList();

        if (currentQuestionGroup) {
          this.CurrentQuestionGroup = this.QuestionGroupsInOrder.filter(a => a.id === currentQuestionGroup)[0];
        } else {
          this.CurrentQuestionGroup = this.QuestionGroupsInOrder[0];
        }
        this.getFilledResponsesPercentageResponse = responses[1];
        this.responsesPercentagesChangedSubject.next(this.getFilledResponsesPercentageResponse);

        this.loadQuestionsForTheCurrentQuestionGroupCommand();
        this.surveyInitialisedSubject.next(true);
      }, (_) => {
        this.progressSpinnerService.hide();
        this.surveyInitialisedSubject.next(false);
    }));
  }

  loadQuestionsForTheCurrentQuestionGroupCommand() {
    this.progressSpinnerService.show();
    this.questionAnswerService
      .apiQuestionAnswerGet$Json(
        {
          questionGroupId: this.CurrentQuestionGroup.id,
          language: this.languageService.language
        })
      .subscribe(
        data => {
          this.QuestionAnswersForTheCurrentQuestionGroup = data;
          this.SetCurrentSection(data.questionListInGroup[0].surveySectionId);
          this.questionGroupLoadedSubject.next(true);
          this.progressSpinnerService.hide();
          this.GoToNextQuestionGroupIfNoQuestionsVisible();
        },
        _ => {
          this.progressSpinnerService.hide();
          this.questionGroupLoadedSubject.next(false);
        });
  }

  saveQuestionAnswersCommand(selectedAnswerOptionIdList: SelectedOptionRequest[], questionId) {
    this.isSaving = true;
    this.questionAnswerService.apiQuestionAnswerPost$Json({
      body: {
        selectedAnswerOptionIdList,
        questionId
      }
    })
    .pipe(
      tap(_ => {
        if (this.foyDataService.isCurrencyQuestion(questionId)) {
          this.foyDataService.updateCurrency();
        }
      }),
      mergeMap(data => {
        if (data.successStatus) {
          this.messageService.add({ severity: 'info', summary: this.translate.instant("Questionnaire updated"), detail: this.translate.instant("Inputs saved successfully"), life: 5000, closable: true });
          this.AllQuestionAnswersInstanceOptionIdSelectedList = data.allQuestionAnswersInstanceOptionIdSelectedList;
        }

        this.answerSavedSubject.next((data));
        this.isSaving = false;
        return this.questionAnswerService.apiQuestionAnswerGetFilledResponsesPercentageGet$Json()
      })
    )
    .subscribe(
      data => {
        this.getFilledResponsesPercentageResponse = data;
        this.responsesPercentagesChangedSubject.next(data);
        this.pendingChangesService.setHasPendingChanges(false);
      },
      _ => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant("Inputs saved successfully"), sticky: true });
        this.answerSavedSubject.next({ successStatus: false, questionId });
        this.isSaving = true;
        this.pendingChangesService.setHasPendingChanges(false);
      });
  }

  deleteQuestionAnswersCommand(questionId) {
    this.saveQuestionAnswersCommand([], questionId);
  }

  checkVisibilityCondition(isComponentVisible: boolean, dependsOnAnswerOptionIdCollection: number[], questionId: number): boolean {
    if (dependsOnAnswerOptionIdCollection.length > 0) {
      const shouldBeVisible = this.AllQuestionAnswersInstanceOptionIdSelectedList.filter(a => dependsOnAnswerOptionIdCollection.includes(a)).length > 0;
      if (shouldBeVisible === false && isComponentVisible === true) {
        this.deleteQuestionAnswersCommand(questionId);
      }
      return shouldBeVisible;
    } else {
      return true;
    }
  }

  private CreateOrderedQuestionGroupList() {
    this.QuestionGroupsInOrder = [];
    this.Survey.surveySectionGetResponseList.forEach(section => {
      this.QuestionGroupsInOrder = this.QuestionGroupsInOrder.concat(section.questionGroupList);
    });
    this.QuestionGroupsInOrder.sort((n1, n2) => n1.orderNumber - n2.orderNumber);
  }

  private SetCurrentSection(sectionId) {
    this.CurrentSection = this.GetSurveySections().filter(a => a.id === sectionId)[0];
  }

  public GetSurveySections() {
    return this.Survey.surveySectionGetResponseList.sort((a, b) => a.orderNumber - b.orderNumber);
  }

  public GetActiveSurveySection() {
    return this.CurrentSection;
  }

  public GetCurrentQuestionGroup() {
    return this.CurrentQuestionGroup;
  }

  public GetAllQuestionAnswersInstanceOptionIdSelectedList() {
    return this.AllQuestionAnswersInstanceOptionIdSelectedList;
  }

  public GetQuestionAnswersForTheCurrentQuestionGroup() {
    return this.QuestionAnswersForTheCurrentQuestionGroup;
  }

  public GetProgress() {
    this.getFilledResponsesPercentageResponse.overallPercentage
    if (this.Survey) {
      return Math.round(this.getFilledResponsesPercentageResponse.overallPercentage * 100);
    }
    return 0;
  }

  public GoToNextQuestionGroup() {
    this.isForwardDirection = true;
    const index = this.QuestionGroupsInOrder.findIndex(a => a.id === this.CurrentQuestionGroup.id);
    if (!this.IsLastQuestionGroupVisible()) {
      this.CurrentQuestionGroup = this.QuestionGroupsInOrder[index + 1]; // 
      this.loadQuestionsForTheCurrentQuestionGroupCommand();
    }
    this.changeUrl();
  }

  public GoToLastQuestionGroup() {
    this.CurrentQuestionGroup = this.QuestionGroupsInOrder[this.QuestionGroupsInOrder.length - 1]; // 
    this.loadQuestionsForTheCurrentQuestionGroupCommand();
    var user = this.accountService.getUser();
    this.router.navigate([`./surveys/${user.surveyToken}`], {
      queryParams: { currentQuestionGroup: this.CurrentQuestionGroup.id },
      queryParamsHandling: 'merge'
    });
  }

  public GoToSummary() {
    this.router.navigate(['/summary']);
  }

  public GoToQuestionGroup(id: number) {
    this.isForwardDirection = true;
    const index = this.QuestionGroupsInOrder.findIndex(a => a.id === id);
    this.CurrentQuestionGroup = this.QuestionGroupsInOrder[index]; // 
    this.loadQuestionsForTheCurrentQuestionGroupCommand();
    this.changeUrl();
  }

  public IsFirstQuestionGroupVisible() { // 
    return this.QuestionGroupsInOrder.findIndex(a => a.id === this.CurrentQuestionGroup.id) === 0;
  }

  public IsLastQuestionGroupVisible() { // 
    return this.QuestionGroupsInOrder.findIndex(a => a.id === this.CurrentQuestionGroup.id) === this.QuestionGroupsInOrder.length - 1;
  }

  public GoToPreviousQuestionGroup() {
    this.isForwardDirection = false;
    const index = this.QuestionGroupsInOrder.findIndex(a => a.id === this.CurrentQuestionGroup.id);
    if (index > 0) {
      this.CurrentQuestionGroup = this.QuestionGroupsInOrder[index - 1];
      this.loadQuestionsForTheCurrentQuestionGroupCommand();
    }
    this.changeUrl();
  }

  public GetRegionDataObservable(inst = 0): Observable<SurveyInstanceDataResponse> {
    return this.surveyInstanceDataService.apiSurveyInstanceDataGet$Json({ type: 'region', instance: inst });
  }

  public GetCountryDataObservable(): Observable<SurveyInstanceDataResponse> {
    return this.surveyInstanceDataService.apiSurveyInstanceDataGet$Json({ type: 'country' });
  }

  public GetCountryRegionDataObservable(): Observable<SurveyInstanceDataResponse> {
    return this.surveyInstanceDataService.apiSurveyInstanceDataGet$Json({ type: 'countryregion' });
  }

  public GetParticipationConsentDataObservable(): Observable<SurveyInstanceDataResponse> {
    return this.surveyInstanceDataService.apiSurveyInstanceDataGet$Json({ type: 'participationconsent' });
  }

  public GetEmailDataObservable(): Observable<SurveyInstanceDataResponse> {
    return this.surveyInstanceDataService.apiSurveyInstanceDataGet$Json({ type: 'email' });
  }

  public CallDeleteSurvey(): Observable<boolean> {
    return this.surveyService.apiSurveyDelete$Json();
  }

  public AreAllQuestionsOnTheCurrentGroupAnswered() {
    const notProvidedQuestionTextNumberList = [];
    this.QuestionAnswersForTheCurrentQuestionGroup.questionListInGroup.filter(
      a =>
        a.dependsOnAnswerOptionIdCollection.length === 0 ||
        a.dependsOnAnswerOptionIdCollection.filter(b => this.AllQuestionAnswersInstanceOptionIdSelectedList.includes(b)).length > 0
    ).forEach(q => {
      const questionAnswerOptionIds = q.answerCollection.reduce((accumulator, value) => accumulator.concat(value.answerOptionCollection), []).map(a => a.id);
      if (this.AllQuestionAnswersInstanceOptionIdSelectedList.filter(a => questionAnswerOptionIds.includes(a)).length === 0) {

        notProvidedQuestionTextNumberList.push(q.numberText);

      }
    });
    return notProvidedQuestionTextNumberList;
  }

  public GoToNextQuestionGroupIfNoQuestionsVisible() {
    if (this.GroupHasOnlyHiddenQuestions()) {
      if (this.isForwardDirection) {
        this.GoToNextQuestionGroup();
      } else {
        this.GoToPreviousQuestionGroup();
      }
    }
  }

  public GroupHasOnlyHiddenQuestions() {
    let visibleComponentsCount = 0;
    this.QuestionAnswersForTheCurrentQuestionGroup.questionListInGroup.filter(
      q => {
        if (q.dependsOnAnswerOptionIdCollection.length > 0) {
          if (this.AllQuestionAnswersInstanceOptionIdSelectedList.filter(b => q.dependsOnAnswerOptionIdCollection.includes(b)).length > 0) {
            visibleComponentsCount++;
          }
        } else {
          visibleComponentsCount++;
        }
      });
    return visibleComponentsCount === 0;
  }

  public ChangeSuppliersCount(newSuppliersCountValue) {
    this.lastObservedSuppliersCountSubject.next(newSuppliersCountValue);
  }

  public ChangeSuppliersSpend(newSuppliersSpendValue) {
    this.lastObservedSuppliersSpendSubject.next(newSuppliersSpendValue);
  }

  public GetDiverseSuppliersCountDataObservable(): Observable<SurveyInstanceDataResponse> {
    return this.surveyInstanceDataService
      .apiSurveyInstanceDataGet$Json({ type: 'diversesupplierscount' })
      .pipe(tap(a => this.lastObservedSuppliersCountValue = a[0]));
  }

  public GetDiverseSuppliersSpendDataObservable(): Observable<SurveyInstanceDataResponse> {
    return this.surveyInstanceDataService
      .apiSurveyInstanceDataGet$Json({ type: 'diversesuppliersspend' })
      .pipe(tap(a => this.lastObservedSuppliersSpendValue = a[0]));
  }

  public uploadFiles(files: File[], answerOptionId: number, freeText: string, questionId: number, successCallback: (uploadedFiles: UploadedFileResponse[]) => void) {
    this.isSaving = true;
    this.filesService.apiFilesPost$Json({
      AnswerOptionId: answerOptionId,
      body: {
        Files: files,
      },
        FreeText: freeText,
        QuestionId: questionId
    }).subscribe(
      data => {
        this.isSaving = false;
        successCallback(data);
      },
      _ => {

        this.answerSavedSubject.next({ successStatus: false });
        this.isSaving = true;
      });
  }

  public getQuestionGroupsInOrder() {
    return this.QuestionGroupsInOrder;
  }

  submitSurveyCommand() {
    this.isSaving = true;
    this.surveyService.apiSurveySubmitSurveyPut$Json()
      .subscribe(
        isSuccessStatus => {
          if (isSuccessStatus) {
            this.messageService.add({ severity: 'info', summary: 'Info', detail: this.translate.instant("Questionnaire successfully submitted"), life: 5000, closable: true });

            this.router.navigate(['/end-of-survey']);
            this.accountService.markSurveyAsSubmitted();
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant("Inputs saved successfully"), sticky: true });
          }
          this.isSaving = false;
        },
        _ => {
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant("Inputs saved successfully"), sticky: true });
          this.answerSavedSubject.next({ successStatus: false });
          this.isSaving = true;
        });
  }

  getFilledResponsesPercentage() : GetFilledResponsesPercentageResponse {
    return this.getFilledResponsesPercentageResponse;
  }

  private changeUrl() {
    this.router.navigate([], {
      queryParams: { currentQuestionGroup: this.CurrentQuestionGroup.id },
      queryParamsHandling: 'merge'
    });
  }
}
