import { Component, OnInit, ViewEncapsulation, ViewChild, ComponentFactoryResolver, HostListener, OnDestroy, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { SurveyStateService } from 'src/app/services/surveyState.service';

import { QuestionItem } from 'src/app/question-item';
import { QuestionListDirective } from 'src/app/question-list.directive';
import { QuestionComponent } from 'src/app/question.component';
import { ProgressSpinnerService } from 'src/app/services/progress-spinner.service';
import { MessageService } from 'primeng/api';
import { SingleSelectQuestionComponent } from 'src/app/questions/single-select-question/single-select-question.component';
import { MultiSelectQuestionComponent } from 'src/app/questions/multi-select-question/multi-select-question.component';
import { SingleSelectSeriesQuestionComponent } from 'src/app/questions/single-select-series-question/single-select-series-question.component';
import { MultiSelectSeriesQuestionComponent } from 'src/app/questions/multi-select-series-question/multi-select-series-question.component';
import { ImageSingleSelectQuestionComponent } from 'src/app/questions/image-single-select-question/image-single-select-question.component';
import { CategoryMultiSelectQuestionComponent } from 'src/app/questions/category-multi-select-question/category-multi-select-question.component';
import { FreeTextQuestionComponent } from 'src/app/questions/free-text-question/free-text-question.component';
import { FreeTextNumericalQuestionComponent } from 'src/app/questions/free-text-numerical-question/free-text-numerical-question.component';
import { Subscription, forkJoin, from, of } from 'rxjs';
import { MatrixDropdownQuestionComponent } from 'src/app/questions/matrix-dropdown-question/matrix-dropdown-question.component';
import { MatrixNumericalQuestionComponent } from 'src/app/questions/matrix-numerical-question/matrix-numerical-question.component';
// import { MapCountrySelectionQuestionComponent } from 'src/app/questions/map-country-selection-question/map-country-selection-question.component';
import { CategoryMultiSelectOrderedQuestionComponent } from 'src/app/questions/category-multi-select-ordered-question/category-multi-select-ordered-question.component';
// import { RegionBasedCountrySelectionQuestionComponent } from 'src/app/questions/region-based-country-selection-question/region-based-country-selection-question.component';
// import { RegionBasedCountryExpandSelectionQuestionComponent } from 'src/app/questions/region-based-country-expand-selection-question/region-based-country-expand-selection-question.component';
// import { RegionBasedMaturityLevelSelectionQuestionComponent } from 'src/app/questions/region-based-maturity-level-selection-question/region-based-maturity-level-selection-question.component';
import { EmailQuestionComponent } from 'src/app/questions/email-question/email-question.component';
import { MatrixAutocompleteQuestionComponent } from 'src/app/questions/matrix-autocomplete-question/matrix-autocomplete-question.component';
import { environment } from 'src/environments/environment';
import { UrlloginService } from 'src/app/services/urllogin.service';
import { MultiLevelComponent } from 'src/app/questions/multi-level/multi-level.component';
import { DividerComponent } from 'src/app/questions/divider/divider.component';
import { TextAreaQuestionComponent } from 'src/app/questions/text-area-question/text-area-question.component';
import { FileUploadQuestionComponent } from 'src/app/questions/file-upload-question/file-upload-question.component';
import { MatrixMixedQuestionComponent } from 'src/app/questions/matrix-mixed-question/matrix-mixed-question.component';
import { AccountService } from 'src/app/services/account.service';
import { MatrixTextQuestionComponent } from 'src/app/questions/matrix-text-question/matrix-text-question.component';
import { TextQuestionComponent } from 'src/app/questions/text-question/text-question.component';
import { SurveyContentComponent } from '../survey-content/survey-content.component';
import { QuestionAnswerService } from 'src/app/api/services';
import { LanguageService } from 'src/app/services/language.service';
import { QuestionAnswerGetResponse, QuestionGroupResponse } from 'src/app/api/models';
import { getQuestions } from '../questions-helper';
import { concatMap, tap } from 'rxjs/operators';
import { PdfGenerationService } from 'src/app/services/pdf-generation.service';
import * as html2pdf from 'html2pdf.js';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { InformationDialogComponent } from 'src/app/dialogs/information-dialog/information-dialog.component';

@Component({
  selector: 'app-summary-survey-content',
  templateUrl: './survey-summary-content.component.html',
  styleUrls: ['./survey-summary-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveySummaryContentComponent implements OnInit, AfterViewInit {
  @ViewChildren(QuestionListDirective) questionList: QueryList<QuestionListDirective>;
  currentPageQuestions: QuestionItem[];
  questionGroups: QuestionGroupResponse[];
  sectionTitleHeaders: string[] = [];

  private answerSavedSubscription: Subscription;
  private questionGroupLoadedSubscription: Subscription;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private messageService: MessageService,
    private surveyStateService: SurveyStateService,
    private progressSpinnerService: ProgressSpinnerService,
    private questionAnswerService: QuestionAnswerService,
    private languageService: LanguageService,
    private pdfGenerationService: PdfGenerationService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
    this.load();
  }

  private load() {
    this.progressSpinnerService.show();

    this.questionGroups = this.surveyStateService.getQuestionGroupsInOrder();

    var questionGroupsRequest = this.questionGroups.length > 0 ? of(this.questionGroups) : this.surveyStateService.initSurveyCommand();

    questionGroupsRequest.pipe(
      tap(data => this.questionGroups = this.surveyStateService.getQuestionGroupsInOrder()),
      concatMap(() => {
        const tasks$ = [];
        this.questionGroups.forEach(qg => {
          tasks$.push(this.questionAnswerService
            .apiQuestionAnswerGet$Json({
              questionGroupId: qg.id,
              language: this.languageService.language
            }))
        });
        return forkJoin(tasks$);
      })
    ).subscribe((responses: QuestionAnswerGetResponse[]) => {
      this.loadComponent(responses);
      this.progressSpinnerService.hide()
    }, _ => {
      this.progressSpinnerService.hide()
    });
  }

  loadComponent(questionsGroups: QuestionAnswerGetResponse[]) {
    // setTimeout(() => {
      try {

        var surveySections = this.surveyStateService.GetSurveySections();

        this.questionList.forEach((question, index) => {
          const viewContainerRef = question.viewContainerRef;
          // viewContainerRef.clear();

          var questionsInGroup = getQuestions(questionsGroups[index]);
          this.sectionTitleHeaders.push(surveySections[index].text);
          questionsInGroup.forEach(question => {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(question.component);
            const componentRef = viewContainerRef.createComponent(componentFactory);
            (componentRef.instance as QuestionComponent).questionData = question.questionData;
            (componentRef.instance as QuestionComponent).questionAnswerInstanceList = question.questionAnswerInstanceList;
            (componentRef.instance as QuestionComponent).messageService = this.messageService;
            (componentRef.instance as QuestionComponent).readonly = true;
            // (componentRef.instance as QuestionComponent).surveyStateService = this.surveyStateService;
          });
        });
      } catch (error) {
        // Handle the error
        console.error('An error occurred:', error);
      }
    // }, 5000);
  }

  submitClicked() {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      data: {
        message: this.translate.instant('Are you sure you want to submit questionnaire?')
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.surveyStateService.submitSurveyCommand();
      }
    });
  }

  generatePdf() {
    var elements = document.querySelectorAll<HTMLElement>('.pdf');

    var array = Array.from(elements);
    // array = array.slice(0, -1);
    var content = this.combineElements(array);

    const options = {
      margin: 10,
      filename: 'summary.pdf',
      pagebreak: { before: 'app-matrix-numerical-question' },
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait', pagesplit: false },
    };

    var dialogRef = this.dialog.open(InformationDialogComponent, {
      disableClose: false,
      data: {
        message: this.translate.instant('PDF is being generated. This may take a few seconds')
      }
    });

    setTimeout(() => this.downloadPDF(array, options), 100);
  }

  private downloadPDF(elements, options) {
    let worker = html2pdf()
      .set(options)
      .from(elements[0])
  
    if (elements.length > 1) {
      worker = worker.toPdf() // worker is now a jsPDF instance
  
      elements.slice(1).forEach((element, index) => {
        worker = worker
          .get('pdf')
          .then(pdf => {
            pdf.addPage()
          })
          .from(element)
          .toContainer()
          .toCanvas()
          .toPdf()
      })
    }

    worker = worker.save()
  }

  private combineElements(elements: HTMLElement[]): HTMLElement {
    const combinedElement = document.createElement('div');

    elements.forEach(element => {
      combinedElement.appendChild(element.cloneNode(true));
    });

    return combinedElement;
  }
}

