import { Component, OnInit, HostListener } from '@angular/core';
import { RegistrationService } from '../../api/services';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from 'src/app/api/models';
import { CacheService } from 'src/app/services/cache.service';
import { switchMap, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { FOYDataService } from 'src/app/services/foy-data.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  isLegalAccepted: boolean = false;
  registerClicked = false;
  registrationForm: FormGroup;

  genderOptions = [
    "Male",
    "Female",
    "Transgender",
    "Other"
  ];

  constructor(
    private registrationService: RegistrationService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private cacheService: CacheService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private foyDataService: FOYDataService
  ) { }

  ngOnInit() {
    this.registrationForm = this.fb.group({
      company: ['', Validators.required],
      selfDescription: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      fax: [''],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });

    var form = this.cacheService.getRegistrationFormData();
    if (form) {
      this.registrationForm.patchValue(form);  
    }
  }

  public ngOnDestroy(): void {
    this.cacheService.setRegistrationFormData(this.registrationForm.value);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: any) {
    if (event.keyCode === 39 && this.canRegister()) {
      this.buttonRegisterClicked();
    }
  }

  legalAccepted(event : CheckboxChangeEvent) {
    var value = event.checked;
    this.isLegalAccepted = value;
  }

  canRegister() {
    return this.registrationForm.valid && this.registrationForm.controls.password.value === this.registrationForm.controls.confirmPassword.value;
  }

  buttonRegisterClicked() {
    var language = this.translate.currentLang;

    var email = this.registrationForm.controls.email.value;
    var password = this.registrationForm.controls.password.value;
    this.registrationService.apiRegistrationPost$Json({body: {
      company: this.registrationForm.controls.company.value,
      email: email,
      fax: this.registrationForm.controls.fax.value,
      firstName: this.registrationForm.controls.firstName.value,
      lastName: this.registrationForm.controls.lastName.value,
      password: password,
      phone: this.registrationForm.controls.phone.value,
      selfDescription: this.registrationForm.controls.selfDescription.value,
      language: language == "de" ? LanguageEnum.German : LanguageEnum.English
    }}).pipe(switchMap(data => {
      if (data === true) {
        this.registerClicked = true;
        this.messageService.add({ severity: 'info', summary: 'Info', detail: this.translate.instant('Mail send to: ') + this.registrationForm.controls.email.value, sticky: false });
        return this.accountService.login(email, password).pipe(tap(_ => this.foyDataService.init()));
      } else {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('Registration error'), sticky: false });
      }
    }
    )).subscribe(data => {
      if (data.errorMessage == null) {
        this.registerClicked = true;
        this.messageService.add({ severity: 'info', summary: 'Info', detail: this.translate.instant('Successfully logged in'), sticky: false });
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/2024-competition';
        this.router.navigateByUrl(returnUrl);
      } else {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('Login error'), sticky: false });
      }
    }, _ => {
      this.messageService.clear();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translate.instant('Registration exception'), sticky: false });
    });
  }
}
